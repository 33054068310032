import React, { useState } from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { compose } from 'recompose';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import { ELSPageHeader } from '@els/els-component-shared-ts-react';
import cx from 'classnames';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { locationActions } from '../../redux/location/location.actions';
import { RoutePath } from '../../components/app/app.constants';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { locationSelectors } from '../../redux/location/location.selectors';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { RemediationBaseState } from './RemediationBase.page';
import RemediationActivityProgress from './RemediationActivityProgress.component';
import {
  getActivityTypeFromRecommendation,
  getAssessmentGoalFromRecommendation,
  getAssessmentGoalsItemsTotals,
  getAttemptById,
  getNextRecommendation,
  getQuizSessionResults,
  getRecommendationFromAttempt,
  isQuizComplete,
} from './remediation-home.utilities';
import { RemediationActivityType } from '../../apis/florence-facade/florence-facade.dtos';
import RemediationQuiz from './RemediationQuiz.component';
import { ELSButtonIconPosition } from '../../models/button.models';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import RemediationCompleteBox from './RemediationCompleteBox.component';
import RemediationRecommendation from './RemediationRecommendation.component';

type RecommendationPostPlayerQuizPropsOnly = {
  baseState: RemediationBaseState;
}

const mapDispatchToProps = {
  trackAction: studyActions.trackAction,
  redirect: locationActions.redirect,
  replaceURL: locationActions.replaceURL,
};
const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  appLinkData: studySelectors.getLinkData(state),
  location: locationSelectors.getLocation(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type RecommendationPostPlayerQuizState = {
  step: number;
}

const defaultState = {
  step: 0
};

export type RecommendationPostPlayerQuizProps = PropsFromRedux & RecommendationPostPlayerQuizPropsOnly & ELSPropsFromModalService;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const RecommendationPostPlayerQuizComponent = (props: RecommendationPostPlayerQuizProps) => {

  const [state, setState] = useState<RecommendationPostPlayerQuizState>(defaultState);
  const mergeState = (newState: Partial<RecommendationPostPlayerQuizState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  const {
    redirect,
    baseState,
    appLinkData
  } = props;
  const handleReturnNavigation = () => {
    props.replaceURL(RoutePath.REMEDIATION_BASE);
  };

  const { outPostBody } = appLinkData;

  const attempt = getAttemptById(baseState.recommendationAttempts, outPostBody.recommendationAttemptId);
  const recommendation = getRecommendationFromAttempt(baseState.recommendations, attempt);
  const activityType = getActivityTypeFromRecommendation(recommendation);
  const assessmentGoal = getAssessmentGoalFromRecommendation(baseState.assessment, recommendation);
  const quizSessionResults = getQuizSessionResults(baseState, outPostBody);

  const statusCounts = getAssessmentGoalsItemsTotals(
    {
      assessmentGoals: [assessmentGoal],
      activityType: RemediationActivityType.ASSESS,
      baseState: props.baseState,
    }
  );

  if (!recommendation) {
    return null;
  }

  const nextRecommendation = getNextRecommendation(baseState, getAssessmentGoalFromRecommendation(baseState.assessment, recommendation));

  return (
    <div className="c-ssa-remediation-post-player">
      <div className="c-ssa-remediation-post-player__header">
        <ELSPageHeader onCloseClick={handleReturnNavigation} title="Study Assignment" />
      </div>

      <div className="c-ssa-remediation-post-player__body">
        <div className="c-ssa-remediation-post-player__window">

          {
            state.step === 0 && (

              <div>

                {
                  isQuizComplete(statusCounts) && (
                    <div className="u-els-margin-bottom">
                      <RemediationCompleteBox
                        activityType={RemediationActivityType.APPLY}
                      />
                    </div>
                  )
                }

                <div>
                  <RemediationActivityProgress
                    assessmentGoals={props.baseState.assessment.assessmentGoals}
                    baseState={props.baseState}
                    activityType={activityType}
                  />
                </div>

                <h4 className="o-els-container o-els-container--2x u-els-text-center">

                  {
                    quizSessionResults && (
                      <div>
                        Great job! In your most recent quiz attempt, you answered <strong>{quizSessionResults.totalQuestions} questions</strong> and
                        got <strong>{quizSessionResults.totalCorrect} correct</strong>.
                      </div>
                    )
                  }
                </h4>

              </div>

            )
          }

          {
            state.step === 1 && (
              <div>
                <div className="o-els-container">
                  <FlexLayout>
                    <FlexItem>
                      <button
                        type="button"
                        className="u-els-anchorize"
                        onClick={() => {
                          mergeState({ step: 0 });
                        }}
                      >
                        <IconWithText
                          iconName="arrow-left"
                          iconPrefix="hmds"
                          iconPosition={ELSButtonIconPosition.LEFT}
                        >
                          Go back
                        </IconWithText>
                      </button>
                    </FlexItem>
                  </FlexLayout>
                </div>

                <div className="o-els-container u-els-text-center">
                  <h3>Next recommendation</h3>
                </div>

                <div className="o-els-container">
                  <RemediationQuiz
                    assessmentGoal={assessmentGoal}
                    baseState={baseState}
                    recommendation={recommendation}
                    statusCounts={statusCounts}
                  />
                </div>

              </div>
            )
          }

          {
            state.step === 2 && (
              <div>
                <div className="o-els-container">
                  <FlexLayout>
                    <FlexItem>
                      <button
                        type="button"
                        className="u-els-anchorize"
                        onClick={() => {
                          mergeState({ step: 0 });
                        }}
                      >
                        <IconWithText
                          iconName="arrow-left"
                          iconPrefix="hmds"
                          iconPosition={ELSButtonIconPosition.LEFT}
                        >
                          Go back
                        </IconWithText>
                      </button>
                    </FlexItem>
                  </FlexLayout>
                </div>

                <div className="o-els-container u-els-text-center">
                  <h3>Next recommendation</h3>
                </div>

                <div className="o-els-container">
                  <RemediationRecommendation
                    handleLaunch={() => {
                      // eslint-disable-next-line no-alert
                      alert('launch');
                    }}
                    recommendation={nextRecommendation}
                    isExpanded
                    recommendationAttempts={baseState.recommendationAttempts}
                    launchButtonText="Launch"
                  />
                </div>

              </div>
            )
          }

          <div className="u-els-margin-top-2x">
            <FlexLayout modifiers={[
              FlexLayoutModifier.GUTTERS,
              FlexLayoutModifier.CENTER,
            ]}>
              <FlexItem>
                <button
                  type="button"
                  className="c-els-button c-els-button--secondary c-els-button--small"
                  onClick={() => redirect(RoutePath.REMEDIATION_BASE)}
                >
                  Back to personalized learning plan
                </button>
              </FlexItem>
              <FlexItem isRender={state.step === 0}>
                <button
                  type="button"
                  className={
                    cx('c-els-button c-els-button--small', {
                      'c-els-button--secondary': !!nextRecommendation,
                      'c-els-button--primary': !nextRecommendation,
                    })
                  }
                  onClick={() => {
                    mergeState({ step: 1 });
                  }}
                >
                  Continue quizzing
                </button>
              </FlexItem>
              <FlexItem isRender={state.step === 0 && !!nextRecommendation}>
                <button
                  type="button"
                  className={cx('c-els-button c-els-button--small c-els-button--primary')}
                  onClick={() => {
                    mergeState({ step: 2 });
                  }}
                >
                  Next recommendation
                </button>
              </FlexItem>
            </FlexLayout>
          </div>

        </div>
      </div>

    </div>
  );
};

const enhancers = [
  connector,
  ELSWithModalService
];

const RecommendationPostPlayerQuiz = compose<null, RecommendationPostPlayerQuizPropsOnly>(...enhancers)(RecommendationPostPlayerQuizComponent);

export default RecommendationPostPlayerQuiz;
