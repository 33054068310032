import React from 'react';
import { RemediationActivityType } from '../../apis/florence-facade/florence-facade.dtos';
import { ActivityTypeConfigMap } from './remediation-home.models';

type RemediationCompleteBoxProps = {
  activityType: RemediationActivityType;
}

// const contentTypes = getContentTypesFromActivityType(activityType);
// const possibleCommonMessage = (
//   <div>
//     Any remaining work will not count toward your requirements.
//     You can review any of the additional {getContentTypeDisplay(contentTypes).toLowerCase()} as optional.
//   </div>
// );
const RemediationCompleteBox = (props: RemediationCompleteBoxProps) => {

  const { activityType } = props;

  if (!activityType) {
    return null;
  }

  return (
    <div className="c-ssa-remediation-complete-box">
      <h3 className="u-els-margin-bottom-1o2">
        Great work! You’ve completed all {ActivityTypeConfigMap[activityType].title.toLowerCase()} requirements.
      </h3>

      {
        activityType === RemediationActivityType.REVIEW && (
          <div>
            You can review any additional readings and videos as optional.
            But any additional work in this section will not count towards your remaining requirements.
          </div>
        )
      }

      {
        activityType === RemediationActivityType.APPLY && (
          <div>
            You can review or reattempt any of your completed Case Studies if you&apos;d like but this is not required.
          </div>
        )
      }

      {
        activityType === RemediationActivityType.ASSESS && (
          <div>
            You can review or reattempt your quiz questions if you&apos;d like but this is not required.
          </div>
        )
      }

    </div>
  );
};

export default RemediationCompleteBox;
