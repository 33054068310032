import {
  isEmpty,
  keyBy,
  get
} from 'lodash';
import { createSelector } from 'reselect';
import {
  ELSTokenHelper,
  ELSCommonConfig
} from '@els/els-ui-common-react';
import {
  AdobeAnalyticsPageData,
  StudentStudyStore
} from './studentStudy.models';
import { reduxStateDomains } from '../redux.constants';
import {
  filterNotFoundError,
  getEvolveResourcesFromCourseSection,
  getNormalizedInstitution,
  includesConflictError
} from '../redux.utilities';
import {
  FALSE_VALUE,
  FEATURE_FLAG,
  TRUE_VALUE
} from '../../apis/eols-features-api/eols-features-api.constants';
import {
  AssessmentDto,
  AssessmentStatusDto,
  AssignmentDto
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import {
  ExternalEntityDto,
  OsmosisTokenDto
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  AppLinkCookies,
  AppLinkData
} from '../../apis/eols-app-link/eols-app-link.dtos';
import { ELSCourseSectionDto } from '../../models/els.dtos';
import {
  getBooleanFromGroupFeatureFlagWithFallbackToGlobal
} from '../../utilities/featureFlag.utilities';
import {
  SequenceMap
} from '../../apis/ocs-api-service/ocs-api-service.dtos';
import { UNKNOWN } from './studentStudy.constants';
import {
  getEmptyPageData,
  getSemester
} from '../../utilities/adobe-analytics-utilities';
import {
  isInstructor,
  isStudent
} from '../../utilities/common.utilities';
import { convertIsbnsToNormalizedString } from '../../utilities/app.utilities';
import { FeatureFlagDto } from '../../apis/eols-features-api/eols-features-api.dtos';
import { StudentStudyDto } from '../../apis/eaq-app-facade-service/eaq-app-facade-service.dtos';
import { CrosswalkUserDto } from '../../apis/eols-user-management-service/eols-user-management-service.dtos';
import { locationSelectors } from '../location/location.selectors';
import { scrubProps } from '../../utilities/analytics.utilities';
import {
  getIsDirectAccessByFeatureFlag,
  getIsDirectAccessByIsbns
} from '../../pages/ai-chat/ai-chat.utilities';
import { CourseSectionDto } from '../../apis/eols-course-crud/eols-course-crud.dtos';

const getStudyStore = (globalState): StudentStudyStore => {
  return globalState[reduxStateDomains.STUDENTSTUDY_STATE];
};

const getCourseSectionId = globalState => getStudyStore(globalState).courseSectionId;

const getRoleId = globalState => getStudyStore(globalState).roleId;

const getUserId = globalState => getStudyStore(globalState).userId;

const getIsLoading = globalState => {
  return getStudyStore(globalState).pendingRequestCount > 0;
};

const getCourseName = globalState => {
  const appStore = getStudyStore(globalState);
  return get(appStore, 'currentCourse.courseName', '');
};

const getConsumerKey = globalState => {
  const appStore = getStudyStore(globalState);
  return get(appStore, 'currentCourse.consumerKey', '');
};

const getCourse = globalState => getStudyStore(globalState).currentCourse;

const getUsers = globalState => getStudyStore(globalState).users;

const getRawIsbns = globalState => getStudyStore(globalState).isbns;
const getIsbns = createSelector(
  getRawIsbns,
  (isbns) => {
    if (!isbns) {
      return null;
    }
    return isbns.split(',');
  }
);

const getVantageIsbns = (globalState) => {
  return getStudyStore(globalState).vantageIsbns;
};

const getPrimaryTaxonomies = (globalState) => {
  return getStudyStore(globalState).primaryTaxonomies;
};

const getEvolveProducts = createSelector(
  [getIsbns, getVantageIsbns, getCourse],
  getEvolveResourcesFromCourseSection
);

const getUser = globalState => {
  const {
    userId,
    users
  } = getStudyStore(globalState);

  if (!users) {
    return null;
  }

  return users.find(x => x.id === parseInt(userId, 10));
};

const getUserDisplayName = createSelector(
  getUser,
  (user) => {
    if (!user) {
      return null;
    }
    return `${user.firstName}`;
  }
);

const getUserHistory = globalState => {
  return getStudyStore(globalState).userHistory;
};

const getUserHistoryStateCompletedRequests = globalState => {
  return getStudyStore(globalState).userHistoryStateCompletedRequests;
};

const getCurrentUserCourseOwnerRecords = globalState => {
  return getStudyStore(globalState).userCourseOwnerRecords;
};

const getCurrentUserCourseOwnerRecord = createSelector(
  getCurrentUserCourseOwnerRecords,
  (userCourseOwnerRecords) => {
    if (!userCourseOwnerRecords || !userCourseOwnerRecords.length) {
      return null;
    }

    return userCourseOwnerRecords[0];
  }
);

const getIsCourseOwner = createSelector(
  getCurrentUserCourseOwnerRecord,
  (matchingUserRecord) => {
    if (!matchingUserRecord) {
      return false;
    }

    return matchingUserRecord.stateInfo === TRUE_VALUE;
  }
);

const getErrors = globalState => {
  const studyStore = globalState[reduxStateDomains.STUDENTSTUDY_STATE] as StudentStudyStore;

  return studyStore.errors;
};

const getToastableErrors = globalState => {
  const errors = getErrors(globalState);
  return filterNotFoundError(errors);
};

const getInstitution = globalState => {
  return getStudyStore(globalState).currentCourse && getStudyStore(globalState).currentCourse.institution;
};

const getMessages = globalState => getStudyStore(globalState).messages;

const getIsLoadingLanguage = globalState => isEmpty(getStudyStore(globalState).messages);

const getFeatureFlagsGrouped = globalState => getStudyStore(globalState).featureFlagsGrouped;

const getIsMaintenance = createSelector(
  [getFeatureFlagsGrouped, getCourseSectionId],
  (flags, courseSectionId) => {
    const value = getBooleanFromGroupFeatureFlagWithFallbackToGlobal(flags, FEATURE_FLAG.IS_SITE_DOWN_FOR_MAINTENANCE, courseSectionId);
    return value === null ? false : value;
  }
);

const getTaxonomies = (globalState) => getStudyStore(globalState).taxonomies;

const getAssignments = (globalState): AssignmentDto[] => getStudyStore(globalState).assignments;
const getAssignmentsDictionary = createSelector(
  getAssignments,
  (assignments) => {
    return keyBy(assignments, 'id');
  }
);
const getExternalEntities = (globalState): ExternalEntityDto[] => getStudyStore(globalState).externalEntities;

const getAssessmentSubmissionsMap = (globalState) => {
  return getStudyStore(globalState).assessmentSubmissionsMap;
};

const getGroupActivity = (globalState) => {
  return getStudyStore(globalState).groupActivity;
};

const getEolsUserCourseSections = (globalState): ELSCourseSectionDto[] => {
  return get(getStudyStore(globalState), 'eolsUser.courseSections', []);
};

const getEolsCrosswalkUserCourseSections = (globalState): ELSCourseSectionDto[] => {
  const { crosswalkUsers = [] } = getStudyStore(globalState);
  return crosswalkUsers.map(user => user.courseSections).flat();
};

const getEvolveUser = (globalState): CrosswalkUserDto => {
  return getStudyStore(globalState).evolveUser;
};

const getAssessmentStartTimeMap = (globalState): Record<string, Date> => {
  return getStudyStore(globalState).assessmentStartTimeMap;
};

const getAppLinkCookies = (globalState): AppLinkCookies => {
  return getStudyStore(globalState).appLinkCookies;
};

const getLinkData = (globalState): AppLinkData => {
  return getStudyStore(globalState).linkData;
};

const getCollapsedFolderIds = (globalState): string[] => {
  return getStudyStore(globalState).collapsedFolderIds;
};

const getABTestFlavors = (globalState): FeatureFlagDto[] => {
  return getStudyStore(globalState).abTestFlavors;
};

const getUserEmailDomain = (globalState): string => {
  const user = getUser(globalState);
  const emailAddress = !user || !user.emailAddress ? ELSTokenHelper.getUserEmail() : user.emailAddress;
  if (!emailAddress) {
    return UNKNOWN;
  }
  const emailParts = emailAddress.split('@');
  if (!emailParts || emailParts.length !== 2) {
    return UNKNOWN;
  }
  return emailParts[1];
};

const isTestUser = (globalState): string => {
  const emailDomain = getUserEmailDomain(globalState);
  if (emailDomain === UNKNOWN) {
    return UNKNOWN;
  }

  const _isTestUser = ELSCommonConfig.testUserEmailDomains.some((domain) => {
    return emailDomain.includes(domain);
  });
  return _isTestUser ? TRUE_VALUE : FALSE_VALUE;
};

const getDefaultActionProps = (globalState): Record<string, string | number | string[]> => {
  const evolveProducts = getEvolveProducts(globalState);
  const evolveUser = getEvolveUser(globalState);
  const institution = getInstitution(globalState);
  const featureFlagsGrouped = getFeatureFlagsGrouped(globalState);
  const courseSectionId = getCourseSectionId(globalState);
  const isbns = getIsbns(globalState);
  const props = {
    courseSectionId: getCourseSectionId(globalState),
    eolsUserId: getUserId(globalState),
    evolveUserId: evolveUser ? evolveUser.externalUserId : UNKNOWN,
    userRole: getRoleId(globalState),
    isbns: getRawIsbns(globalState),
    isbnsSorted: convertIsbnsToNormalizedString(getIsbns(globalState)),
    institution: institution ? institution.name : null,
    userEmailDomain: getUserEmailDomain(globalState),
    isTestUser: isTestUser(globalState),
    isMasqueradeUser: ELSTokenHelper.isMasqueradeUser(),
    pathname: locationSelectors.getLocation(globalState).pathname,
    institutionNormalized: getNormalizedInstitution(getUserEmailDomain(globalState)),
    appVersion: window.getBuildVersion ? window.getBuildVersion() : null,
    isDirectChatBotAccess: getIsDirectAccessByFeatureFlag(featureFlagsGrouped, courseSectionId, isbns),
    isDirectChatBotAccessByIsbn: getIsDirectAccessByIsbns(evolveProducts, isbns),
    consumerKey: getConsumerKey(globalState)
  };
  return scrubProps(props);
};

const getDefaultChatBotActionProps = (globalState): Record<string, string | number | string[]> => {
  const evolveProducts = getEvolveProducts(globalState);
  const featureFlagsGrouped = getFeatureFlagsGrouped(globalState);
  const courseSectionId = getCourseSectionId(globalState);
  const isbns = getIsbns(globalState);
  const props = {
    isDirectChatBotAccess: getIsDirectAccessByFeatureFlag(featureFlagsGrouped, courseSectionId, isbns),
    isDirectChatBotAccessByIsbn: getIsDirectAccessByIsbns(evolveProducts, isbns),
  };
  return scrubProps(props);
};

const getDefaultAdobeAnalyticsPageData = (globalState): AdobeAnalyticsPageData => {

  const pageData: AdobeAnalyticsPageData = window && window.pageData
    ? window.pageData as AdobeAnalyticsPageData
    : getEmptyPageData();

  return {
    ...pageData,
    education: {
      ...pageData.education,
      courseId: getCourseSectionId(globalState),
      courseName: getCourseName(globalState),
      isbn: convertIsbnsToNormalizedString(getIsbns(globalState)),
      studentId: isStudent(getRoleId(globalState)) ? pageData.visitor.userId || getUserId(globalState) : '',
      instructorId: isInstructor(getRoleId(globalState)) ? pageData.visitor.userId || getUserId(globalState) : '',
      semester: getSemester(),
    }
  };
};

const getModuleSequenceMap = (globalState): SequenceMap => {
  return getStudyStore(globalState).moduleSequenceMap;
};

const getAssessments = (globalState): AssessmentDto[] => {
  return getStudyStore(globalState).assessments;
};

const getEnableDeepLink = (globalState): boolean => {
  return getStudyStore(globalState).enableDeepLink;
};

const getAssignmentInProgressAssessmentMap = createSelector(
  [getAssessments],
  (assessments): Record<number, number> => {
    return assessments.reduce((assignmentInProgressAssessmentMap, curAssessment): Record<number, number> => {
      if (curAssessment.status === AssessmentStatusDto.IN_PROGRESS) {
        return {
          ...assignmentInProgressAssessmentMap,
          [curAssessment.assignmentId]: curAssessment.id,
        };
      }
      return assignmentInProgressAssessmentMap;
    }, {});
  }
);

const getRegisteredToken = globalState => getStudyStore(globalState).registeredToken;

const getConflictErrors = globalState => {
  const errors = getErrors(globalState);
  return includesConflictError(errors);
};

const getStudentStudyAssessments = (globalState): StudentStudyDto[] => {
  return getStudyStore(globalState).studentStudyDto;
};

const getOsmosisToken = (globalState): OsmosisTokenDto => {
  return getStudyStore(globalState).osmosisTokenDto;
};

const getAllUserCourseSections = (globalState): CourseSectionDto[] => {
  return getStudyStore(globalState).allUserCourseSections;
};

export const studySelectors = {
  getStudyStore,
  getCourse,
  getCourseName,
  getUserHistory,
  getUserHistoryStateCompletedRequests,
  getCourseSectionId,
  getCurrentUserCourseOwnerRecord,
  getCurrentUserCourseOwnerRecords,
  getErrors,
  getEvolveProducts,
  getInstitution,
  getIsbns,
  getPrimaryTaxonomies,
  getIsCourseOwner,
  getIsLoading,
  getIsLoadingLanguage,
  getMessages,
  getRoleId,
  getToastableErrors,
  getUser,
  getUserDisplayName,
  getUserId,
  getUsers,
  getTaxonomies,
  getAssignments,
  getAssignmentsDictionary,
  getExternalEntities,
  getAssessmentSubmissionsMap,
  getDefaultActionProps,
  getEolsUserCourseSections,
  getEolsCrosswalkUserCourseSections,
  getEvolveUser,
  getAssessmentStartTimeMap,
  getFeatureFlagsGrouped,
  getAppLinkCookies,
  getLinkData,
  getCollapsedFolderIds,
  getIsMaintenance,
  isTestUser,
  getModuleSequenceMap,
  getAssessments,
  getAssignmentInProgressAssessmentMap,
  getGroupActivity,
  getDefaultAdobeAnalyticsPageData,
  getEnableDeepLink,
  getABTestFlavors,
  getStudentStudyAssessments,
  getRegisteredToken,
  getConflictErrors,
  getOsmosisToken,
  getUserEmailDomain,
  getAllUserCourseSections,
  getDefaultChatBotActionProps,
};
