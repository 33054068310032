/* eslint-disable react/prop-types */

import React from 'react';
import cx from 'classnames';
import { ELSIcon, } from '../../components/els.components';
import {
  ActivityTypeConfigMap,
} from './remediation-home.models';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import {
  RemediationActivityType,
} from '../../apis/florence-facade/florence-facade.dtos';
import {
  getActivityTypeFromAssessmentGoal,
  getAssessmentGoalsTotalsCombined,
  getContentTypeDisplay,
  getContentTypesFromActivityType,
  getProgressDisplay,
  isGoalComplete,
  sortAssessmentGoals,
} from './remediation-home.utilities';
import { AssessmentGoalDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { RemediationBaseState } from './RemediationBase.page';

export type RemediationActivitySectionProps = {
  activityType: RemediationActivityType;
  onTaxonClick: (assessmentGoal: AssessmentGoalDto) => void;
  baseState: RemediationBaseState;
};

const RemediationActivitySection = (props: RemediationActivitySectionProps) => {

  const {
    baseState,
    activityType,
  } = props;

  let assessmentGoals = null;

  if (baseState && baseState.assessment) {
    assessmentGoals = baseState.assessment.assessmentGoals.filter((goal) => {
      return getActivityTypeFromAssessmentGoal(goal) === activityType;
    });
    assessmentGoals = sortAssessmentGoals(assessmentGoals);
  }

  const totals = getAssessmentGoalsTotalsCombined({
    assessmentGoals,
    activityType,
    baseState,
  });

  const contentTypes = getContentTypesFromActivityType(activityType);
  const contentTypesDisplay = getContentTypeDisplay(contentTypes);

  return (
    <div className={`c-ssa-remediation-activity-section c-ssa-remediation-activity-section--${activityType.toLowerCase()}`}>

      <div className="c-ssa-remediation-activity-section__checklist-header">

        <div>
          <FlexLayout
            modifiers={[
              FlexLayoutModifier.BOTTOM,
            ]}
          >
            <FlexItem
              modifiers={[
                FlexLayoutModifier.GROW,
              ]}>
              <h3>
                <strong>{ActivityTypeConfigMap[activityType].title}: {contentTypesDisplay}</strong>
              </h3>
            </FlexItem>
            <FlexItem>
              <div className="u-els-color-n7">{getProgressDisplay(totals)}</div>
            </FlexItem>
          </FlexLayout>

        </div>

      </div>

      {
        activityType === RemediationActivityType.ASSESS && (
          <div className="o-els-container">
            You must attempt all <strong>{totals.total}</strong> questions and answer at
            least <strong>{totals.goal}</strong> correctly.
          </div>
        )
      }

      {assessmentGoals && (
        <div>
          {
            assessmentGoals
              .filter((assessmentGoal) => {
                return getActivityTypeFromAssessmentGoal(assessmentGoal) === activityType;
              })
              .map((assessmentGoal) => {

                const statusCounts = getAssessmentGoalsTotalsCombined({
                  assessmentGoals: [assessmentGoal],
                  activityType,
                  baseState,
                });

                return (
                  <button
                    type="button"
                    onClick={() => props.onTaxonClick(assessmentGoal)}
                    key={assessmentGoal.vtwId}
                    className={
                      cx(
                        'u-els-debuttonize',
                        'c-ssa-remediation-activity-section__checklist-item',
                        {
                          'c-ssa-remediation-activity-section__checklist-item--completed': isGoalComplete(statusCounts)
                        }
                      )
                    }
                  >
                    <FlexLayout
                      modifiers={[
                        FlexLayoutModifier.MIDDLE,
                        FlexLayoutModifier.GUTTERS_1o2
                      ]}
                    >
                      <FlexItem>
                        <ELSIcon
                          name={isGoalComplete(statusCounts) ? 'checkmark' : ''}
                          prefix="hmds"
                          size="1x"
                          customClass="c-ssa-remediation-activity-section__checklist-item-icon"
                        />
                      </FlexItem>
                      <FlexItem
                        modifiers={[
                          FlexLayoutModifier.GROW,
                        ]}>
                        <div className="c-ssa-remediation-activity-section__checklist-item-text">
                          <strong>{assessmentGoal.text}</strong>
                          <span>&middot; </span>
                          <span className="u-els-color-n7">{getProgressDisplay(statusCounts)}</span>
                        </div>
                      </FlexItem>
                      <FlexItem>
                        <ELSIcon
                          size="1x"
                          prefix="hmds"
                          name="arrow-right"
                          customClass="c-ssa-remediation-activity-section__checklist-item-icon"
                        />
                      </FlexItem>
                    </FlexLayout>
                  </button>
                );
              })
          }
        </div>
      )}
    </div>
  );
};
export default RemediationActivitySection;
