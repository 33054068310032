import React, {
  useEffect,
  useState
} from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import moment from 'moment';
import {
  ELSPropsFromToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import { compose } from 'recompose';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { locationSelectors } from '../../redux/location/location.selectors';
import { locationActions } from '../../redux/location/location.actions';
import {
  RecommendationAttemptDto,
  RecommendationAttemptStatusDto,
  RecommendationDto
} from '../../apis/florence-facade/florence-facade.dtos';
import {
  getAttemptById,
  getRecommendationContentId
} from '../../pages/remediation/remediation-home.utilities';
import { AssessmentSubmissionDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { BASE_TOAST_CONFIG } from '../../constants/toast.constants';

const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  userId: studySelectors.getUserId(state),
  location: locationSelectors.getLocation(state),
});

const mapDispatchToProps = {
  redirect: locationActions.redirect,
  trackAction: studyActions.trackAction,
  fetchRemediationRecommendationsAction: studyActions.fetchRemediationRecommendationsAction,
  fetchRemediationRecommendationAttemptsAction: studyActions.fetchRemediationRecommendationAttemptsAction,
  fetchEolsAssessmentSubmissionsAction: studyActions.fetchEolsAssessmentSubmissionsAction,
  postSubmissionAction: studyActions.postSubmissionAction,
  putRemediationRecommendationAttemptAction: studyActions.putRemediationRecommendationAttemptAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ComponentProps = PropsFromRedux & ELSPropsFromToastService;

export type RecommendationPlayerHandlers = {
  handleSubmitAttempt: (timeInSeconds: number) => void;
}

export type RecommendationPlayerState = {
  recommendation: RecommendationDto;
  recommendationAttempt: RecommendationAttemptDto;
}

const defaultState: RecommendationPlayerState = {
  recommendation: null,
  recommendationAttempt: null
};

export type RecommendationPlayerProps = PropsFromRedux & RecommendationPlayerState & RecommendationPlayerHandlers;

const withRecommendationPlayer = (WrappedComponent) => {

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const RecommendationPlayer = (props: ComponentProps) => {

    const [state, setState] = useState<RecommendationPlayerState>(defaultState);

    const mergeState = (newState: Partial<RecommendationPlayerState>) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...newState
        };
      });
    };

    useEffect(() => {
      const {
        location,
        fetchRemediationRecommendationsAction,
        fetchRemediationRecommendationAttemptsAction
      } = props;

      if (!location.query) {
        return;
      }

      const {
        recommendationId,
        recommendationAttemptId
      } = location.query;

      if (!recommendationId || !recommendationAttemptId) {
        return;
      }

      Promise.all([
        fetchRemediationRecommendationAttemptsAction([recommendationId]),
        fetchRemediationRecommendationsAction(null, recommendationId)
      ])

        .then(([attempts, recommendations]) => {

          if (!recommendations || !recommendations.length) {
            return;
          }

          const recommendation = recommendations[0];
          const contentId = getRecommendationContentId(recommendation);
          const recommendationAttempt = getAttemptById(attempts, Number(recommendationAttemptId));

          if (!contentId) {
            return;
          }

          mergeState({
            recommendation,
            recommendationAttempt
          });

        });

    }, []);

    const handleSubmitAttempt: RecommendationPlayerHandlers['handleSubmitAttempt'] = (secondsCompleted) => {
      const {
        userId,
        fetchEolsAssessmentSubmissionsAction,
        postSubmissionAction,
        putRemediationRecommendationAttemptAction,
      } = props;

      const {
        recommendationAttempt
      } = state;

      if (!recommendationAttempt) {
        return;
      }

      fetchEolsAssessmentSubmissionsAction([recommendationAttempt.assessmentId])
        .then((assessmentSubmissions) => {
          if (assessmentSubmissions && assessmentSubmissions.length > 0) {
            props.toastService.openToast({
              ...BASE_TOAST_CONFIG,
              component: <div>Attempt already submitted</div>,
              type: ELSWithToastService.types.NEGATIVE
            });
            return;
          }

          const submission: Partial<AssessmentSubmissionDto> = {
            assessmentId: recommendationAttempt.assessmentId,
            userId: parseInt(userId, 10),
            score: 1,
            totalCorrect: 1,
            totalAnswered: 1,
            responseTime: moment().utc().format(),
            offsetSeconds: 0,
            timeSpent: secondsCompleted
          };

          postSubmissionAction(submission).then(() => {
            const updatedAttempt: RecommendationAttemptDto = {
              ...recommendationAttempt,
              status: RecommendationAttemptStatusDto.COMPLETED
            };
            props.toastService.openToast({
              ...BASE_TOAST_CONFIG,
              component: <div>Attempt successfully submitted</div>,
              type: ELSWithToastService.types.POSITIVE
            });
            putRemediationRecommendationAttemptAction(updatedAttempt);
          });
        });
    };

    return <WrappedComponent {...props} {...state} handleSubmitAttempt={handleSubmitAttempt} />;
  };

  const enhancers = [
    connector,
    ELSWithToastService
  ];

  return compose<null, null>(...enhancers)(RecommendationPlayer);
};

export default withRecommendationPlayer;
