import React from 'react';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import {
  ELSPropsFromToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import {
  RecommendationDto,
  RemediationActivityType,
} from '../../apis/florence-facade/florence-facade.dtos';
import {
  getAssessmentGoalsItemsTotals,
} from './remediation-home.utilities';
import { AssessmentGoalDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import RemediationGoalModal from './RemediationGoalModal.component';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { RemediationBaseState } from './RemediationBase.page';
import RemediationQuiz from './RemediationQuiz.component';

type RemediationQuizModalPropsOnly = {
  baseState: RemediationBaseState;
  assessmentGoal: AssessmentGoalDto;
  recommendation: RecommendationDto;
}

const mapDispatchToProps = {
  navigateToApp: studyActions.navigateToApp,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type RemediationQuizModalProps = PropsFromRedux
  & RemediationQuizModalPropsOnly
  & ELSPropsFromModalService
  & ELSPropsFromToastService;

export const RemediationQuizModalId = 'RemediationQuizModalId';

const RemediationQuizModalComponent = (props: RemediationQuizModalProps) => {

  const handleCancelClick = () => {
    props.modalService.closeModal(RemediationQuizModalId);
  };

  const statusCounts = getAssessmentGoalsItemsTotals(
    {
      assessmentGoals: [props.assessmentGoal],
      activityType: RemediationActivityType.ASSESS,
      baseState: props.baseState,
    }
  );

  return (
    <RemediationGoalModal
      assessmentGoal={props.assessmentGoal}
      handleCancelClick={handleCancelClick}
    >
      <RemediationQuiz
        baseState={props.baseState}
        assessmentGoal={props.assessmentGoal}
        recommendation={props.recommendation}
        statusCounts={statusCounts}
      />
    </RemediationGoalModal>
  );
};

const enhancers = [
  connector,
  ELSWithModalService,
  ELSWithToastService
];

const RemediationQuizModal = compose<null, RemediationQuizModalPropsOnly>(...enhancers)(RemediationQuizModalComponent);

export default RemediationQuizModal;
