import {
  RecommendationDataBookDto,
  RecommendationDataOsmosisVideoDto,
  RecommendationDto
} from '../../apis/florence-facade/florence-facade.dtos';

export const getOsmosisVideoIdFromRecommendation = (recommendation: RecommendationDto) => {
  if (!recommendation || !recommendation.recommendationData) {
    return null;
  }
  // TODO: Fix me
  const osmosisRecommendationData = recommendation.recommendationData as unknown as RecommendationDataOsmosisVideoDto;

  const uriPrefix = 'https://osmosis.org/learn/';

  if (!osmosisRecommendationData.source.location.includes(uriPrefix)) {
    return null;
  }
  return osmosisRecommendationData.source.location.replace(uriPrefix, '');
};

export const getBookIdFromRecommendation = (recommendation: RecommendationDto) => {
  if (!recommendation || !recommendation.recommendationData) {
    return null;
  }
  // TODO: Fix me
  const osmosisRecommendationData = recommendation.recommendationData as unknown as RecommendationDataBookDto;
  return osmosisRecommendationData.source.source_id;
};
