import React, { useEffect } from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { compose } from 'recompose';
import { ELSPageLoader } from '@els/els-ui-common-react';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import {
  ELSPropsFromToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import FocusTrap from 'focus-trap-react';
import moment from 'moment';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { ELSProgressBar } from '../../components/els.components';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { locationActions } from '../../redux/location/location.actions';
import RemediationHeader from './RemediationHeader.component';
import {
  getActivityTypeFromAssessmentGoal,
  getAssessmentGoalsTotalsCombined,
  getParentLinkId,
  isRecommendationPartOfGoal
} from './remediation-home.utilities';
import {
  RecommendationDto,
  RemediationActivityType,
} from '../../apis/florence-facade/florence-facade.dtos';
import RemediationQuizModal, { RemediationQuizModalId } from './RemediationQuizModal.component';
import RemediationActivityProgress from './RemediationActivityProgress.component';
import RemediationActivitySection from './RemediationActivitySection.component';
import {
  AssessmentGoalDto,
  AssignmentDto
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { DATE_TIME_PRIMARY } from '../../constants/date.constants';
import RemediationActivityModal, { RemediationActivityModalId } from './RemediationActivityModal.component';
import { locationSelectors } from '../../redux/location/location.selectors';
import { RoutePath } from '../../components/app/app.constants';
import { RemediationBaseState } from './RemediationBase.page';

type RemediationHomePropsOnly = {
  baseState: RemediationBaseState;
};

const mapDispatchToProps = {
  returnAppLink: studyActions.returnAppLink,
  trackAction: studyActions.trackAction,
  redirect: locationActions.redirect,
  navigateToApp: studyActions.navigateToApp,
  replaceURL: locationActions.replaceURL,
};
const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  appLinkData: studySelectors.getLinkData(state),
  appLinkCookies: studySelectors.getAppLinkCookies(state),
  userId: studySelectors.getUserId(state),
  location: locationSelectors.getLocation(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type RemediationHomeProps = PropsFromRedux
  & RemediationHomePropsOnly
  & ELSPropsFromModalService
  & ELSPropsFromToastService;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const RemediationHomeComponent = (props: RemediationHomeProps) => {

  const handleAssessLaunch = (
    fnProps: {
      recommendation: RecommendationDto;
      assessmentGoal: AssessmentGoalDto;
      baseState: RemediationBaseState;
    }
  ) => {

    const {
      recommendation,
      baseState,
    } = fnProps;

    const {
      modalService,
    } = props;

    if (!recommendation) {
      return;
    }

    modalService.openCustomModal({
      modalId: RemediationQuizModalId,
      modal: (
        <FocusTrap>
          <RemediationQuizModal
            assessmentGoal={fnProps.assessmentGoal}
            recommendation={recommendation}
            baseState={baseState}
          />
        </FocusTrap>
      )
    });
  };

  const goToAssessmentPostScreen = () => {

    const {
      location,
      replaceURL,
      baseState
    } = props;

    if (!location || !location.query) {
      return;
    }

    const { quizSessionRecommendationId } = location.query;

    if (!quizSessionRecommendationId) {
      return;
    }
    const recommendation = baseState.recommendations.find((_recommendation: RecommendationDto) => {
      return _recommendation.id.toString() === quizSessionRecommendationId;
    });

    if (!recommendation) {
      return;
    }
    const assessmentGoal = baseState.assessment.assessmentGoals.find((goal) => {
      return isRecommendationPartOfGoal(recommendation, goal);
    });

    if (!assessmentGoal) {
      return;
    }

    replaceURL(RoutePath.REMEDIATION_BASE);
    handleAssessLaunch({
      recommendation,
      assessmentGoal,
      baseState
    });
  };

  useEffect(() => {
    goToAssessmentPostScreen();
  }, [props.location.query]);

  const handleReturnNavigation = () => {
    props.returnAppLink({
      linkId: getParentLinkId(props.appLinkData, props.appLinkCookies),
      returnPostBody: null
    });
  };

  const handleTaxonClick = (assessmentGoal: AssessmentGoalDto) => {

    if (getActivityTypeFromAssessmentGoal(assessmentGoal) === RemediationActivityType.ASSESS) {

      const goalRecommendation = props.baseState.recommendations.find((recommendation) => {
        return isRecommendationPartOfGoal(recommendation, assessmentGoal);
      });

      if (!goalRecommendation) {
        // eslint-disable-next-line no-console
        console.log('No recommendation found for goal');
        return;
      }

      handleAssessLaunch({
        recommendation: goalRecommendation,
        assessmentGoal,
        baseState: props.baseState
      });
      return;
    }

    const {
      modalService,
    } = props;

    modalService.openCustomModal({
      modalId: RemediationActivityModalId,
      modal: (
        <FocusTrap>
          <RemediationActivityModal
            assessmentGoal={assessmentGoal}
            baseState={props.baseState}
          />
        </FocusTrap>
      )
    });

  };

  const getAssessmentGoals = () => {
    return props.baseState.assessment ? props.baseState.assessment.assessmentGoals : null;
  };

  const getTotal = () => {
    const percents = [RemediationActivityType.REVIEW, RemediationActivityType.APPLY, RemediationActivityType.ASSESS].map((activityType) => {
      const activityTotals = getAssessmentGoalsTotalsCombined({
        assessmentGoals: getAssessmentGoals(),
        activityType,
        baseState: props.baseState
      });

      if (!activityTotals.total) {
        return 0;
      }

      return Math.round((activityTotals.completed / activityTotals.total) * 100);
    });

    const total = percents.reduce((acc, percent) => {
      return acc + percent;
    }, 0);

    return Math.round(total / percents.length);
  };

  const getDueDisplay = (assignment: AssignmentDto) => {
    const daysUntil = moment(assignment.dueDate).diff(moment(), 'days');
    return (
      <>
        <span>Due {moment(assignment.dueDate).format(DATE_TIME_PRIMARY)}</span>
        <span>&nbsp;</span>
        <span>({daysUntil <= 0 ? 'Due today' : `In ${daysUntil} days`})</span>
      </>
    );
  };

  if (!props.baseState || !props.baseState.assignment) {
    return null;
  }

  return (
    <>
      {props.baseState.pendingRequestCount > 0 && (<ELSPageLoader />)}
      <div className="c-ssa-remediation-home">
        <RemediationHeader handleReturnNavigation={handleReturnNavigation} />

        <div className="c-ssa-remediation-home__main">
          <div className="c-ssa-remediation-home__center">
            <div className="c-ssa-remediation-home__main-header">

              <FlexLayout modifiers={[
                FlexLayoutModifier.GUTTERS_2X,
                FlexLayoutModifier.MIDDLE,
              ]}>
                <FlexItem modifiers={[FlexLayoutModifier.GROW, FlexLayoutModifier.TOP]}>
                  <div className="u-els-color-secondary">{props.baseState.assignment.title}</div>
                  <h3><strong>Personalized Learning Plan</strong></h3>
                </FlexItem>
                <FlexItem>

                  <div className="u-els-margin-bottom">
                    <div className="c-ssa-remediation-home__progress">
                      <div>{getDueDisplay(props.baseState.assignment)}</div>
                      <FlexLayout modifiers={[
                        FlexLayoutModifier.GUTTERS_1o2,
                        FlexLayoutModifier.MIDDLE,
                      ]}>
                        <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                          <ELSProgressBar progressNum={getTotal()} totalNum={100} />
                        </FlexItem>
                        <FlexItem>
                          <span className="u-els-font-size-meta">{getTotal()}%</span>
                        </FlexItem>
                      </FlexLayout>
                    </div>
                  </div>
                </FlexItem>
              </FlexLayout>

              <div>
                You took the exam, and now its time to make sure you’re on top of your game for next time.
                We&apos;ve analyzed your results and have put together a learning plan personalized to your specific needs.
              </div>

            </div>

            <div className="o-els-container o-els-container--2x">
              <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X]}>
                <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                  <RemediationActivityProgress
                    activityType={RemediationActivityType.REVIEW}
                    assessmentGoals={getAssessmentGoals()}
                    baseState={props.baseState}
                  />
                </FlexItem>
                <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                  <RemediationActivityProgress
                    activityType={RemediationActivityType.APPLY}
                    assessmentGoals={getAssessmentGoals()}
                    baseState={props.baseState}
                  />
                </FlexItem>
                <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                  <RemediationActivityProgress
                    activityType={RemediationActivityType.ASSESS}
                    assessmentGoals={getAssessmentGoals()}
                    baseState={props.baseState}
                  />
                </FlexItem>
              </FlexLayout>

            </div>

            <div>

              <div className="c-ssa-remediation-home__tab">
                <div className="c-ssa-remediation-home__tab-content">
                  <RemediationActivitySection
                    baseState={props.baseState}
                    activityType={RemediationActivityType.REVIEW}
                    onTaxonClick={handleTaxonClick}
                  />
                </div>
              </div>
              <div className="c-ssa-remediation-home__tab">
                <div className="c-ssa-remediation-home__tab-content">
                  <RemediationActivitySection
                    baseState={props.baseState}
                    activityType={RemediationActivityType.APPLY}
                    onTaxonClick={handleTaxonClick}
                  />
                </div>
              </div>
              <div className="c-ssa-remediation-home__tab">
                <div className="c-ssa-remediation-home__tab-content">
                  <RemediationActivitySection
                    baseState={props.baseState}
                    activityType={RemediationActivityType.ASSESS}
                    onTaxonClick={handleTaxonClick}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const enhancers = [
  connector,
  ELSWithModalService,
  ELSWithToastService
];

const RemediationHome = compose<null, RemediationHomePropsOnly>(...enhancers)(RemediationHomeComponent);

export default RemediationHome;
