import React from 'react';
import cx from 'classnames';
import { ELSIcon } from '@els/els-component-form-field-react';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import {
  ActivityTypeConfigMap,
} from './remediation-home.models';
import {
  getActivityTypeFromAssessmentGoal,
} from './remediation-home.utilities';
import { AssessmentGoalDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';

export type RemediationGoalModalProps = {
  children: React.ReactNode;
  handleCancelClick: () => void;
  assessmentGoal: AssessmentGoalDto;
}

const RemediationGoalModal = (props: RemediationGoalModalProps) => {

  const {
    assessmentGoal,
    children,
    handleCancelClick
  } = props;

  const activityType = getActivityTypeFromAssessmentGoal(assessmentGoal);

  return (
    <div>
      <div className="c-els-modal c-els-modal--full">
        <div className="c-els-modal__window u-els-width-1o1@mobile">
          <div className={
            cx(
              'c-ssa-remediation-goal-modal',
              `c-ssa-remediation-goal-modal--${activityType.toLowerCase()}`
            )
          }>

            <div className="u-els-margin-bottom-1x1o2">

              <FlexLayout
                modifiers={[FlexLayoutModifier.TOP, FlexLayoutModifier.GUTTERS]}
              >
                <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                  <h2><strong>{ActivityTypeConfigMap[getActivityTypeFromAssessmentGoal(assessmentGoal)].title}</strong> / {assessmentGoal.text}</h2>
                </FlexItem>
                <FlexItem>
                  <button
                    type="button"
                    className="c-els-modal__close"
                    onClick={handleCancelClick}
                  >
                    <ELSIcon name="close" size="1x" />
                    <span className="u-els-hide-visually">Close this modal window</span>
                  </button>
                </FlexItem>
              </FlexLayout>

              <div className="u-els-margin-top c-els-divider" />
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemediationGoalModal;
