export enum RoutePath {
  ADMIN = '/super-secret-admin-page',
  APP_LINK_REDIRECT = '/redirect',
  CATALOG = '/catalog',
  CW_LOGOUT = '/cwlogout',
  TIMEOUT = '/timedout',
  LOGIN = '/login',
  LOGOUT = '/logout',
  PAGE_NOT_FOUND = '/pageNotFound',
  EAQ_STUDENT_STUDY = '/eaq-student-study',
  EAQ_STUDENT_TOPIC_REPORT = '/eaq-student-topic-report',
  SUPPORT = '/support',
  HELP = '/help',
  AI_CHAT = '/ai-chat',
  ASSESSMENT_START = '/assessment-start',
  AI_CHAT_PERFORMANCE = '/ai-chat-performance',
  REMEDIATION_BASE = '/remediation-home',
  REMEDIATION_QUIZ = '/remediation-quiz',
  AUTHORING_POC = '/authoring-poc',
  AI_CHAT_ADMIN = '/ai-chat-admin',
  OSMOSIS_PLAYER = '/osmosis-player',
  EBOOK_PLAYER = '/ebook-player',
}

export const ServicePath = {
  loginPath: '/login-service/login',
  tokenServicePath: '/token-service',
  userServicePath: '/apiv1/eolsUser/list',
};

export const AppConstants = {
  APP_ID: 'STUDENT_STUDY',
  IDLE_TIMEOUT: 1000 * 60 * 60 * 6,
  PENDO_API_KEY: '94bb3f52-df30-4f69-4ec8-dceb6bffffbe'
};
