/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
// TODO: Address these lint issues

import { orderBy } from 'lodash';
import moment from 'moment';
import { ELSCommonConfig } from '@els/els-ui-common-react';
import {
  HesiExamRemediationSettingsDto,
  QuestionSubmissionDetailsDto,
  QuizSessionDto,
  QuizSessionQuestionDto,
  RecommendationAttemptDto,
  RecommendationAttemptStatusDto,
  RecommendationDataBookDto,
  RecommendationDataOsmosisVideoDto,
  RecommendationDto,
  RecommendationItemDto,
  RemediationActivityType,
  RemediationActivityTypeDto,
  RemediationAssignmentDto,
  RemediationAssignmentSettingsTypeDto,
  RemRecContentTypeDto,
  RemRecItemTypeDto
} from '../../apis/florence-facade/florence-facade.dtos';
import {
  AssessmentPlayerAppLinkOutBody,
  CaseStudyAppLinkOutBody,
  RecommendationItemStatusMap,
  RecommendationStatusMap,
  RemediationContentTypeConfigMap,
  RemediationGoalType,
  RemediationStatusCountLabel,
  RemediationStatusCounts,
  StatusMapConfig
} from './remediation-home.models';
import {
  AssessmentDto,
  AssessmentGoalDto,
  AssessmentStatusDto,
  AssessmentSubmissionDto,
  AssessmentTypeDto,
  AssignmentDto,
  AssignmentGradeType,
  AssignmentScoreType,
  AssignmentTargetType,
  AssignmentType,
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { RemediationBaseState } from './RemediationBase.page';
import {
  AppLinkCookies,
  AppLinkData
} from '../../apis/eols-app-link/eols-app-link.dtos';
import { RoutePath } from '../../components/app/app.constants';
import {
  AppAction,
  Application
} from '../../apis/eols-app-link/eols-app-link.constants';
import { NavigateToApp } from '../../redux/student-study/studentStudy.models';
import {
  getBookIdFromRecommendation,
  getOsmosisVideoIdFromRecommendation
} from '../osmosis-player/osmosis-player.utilities';
import { addSearchParams } from '../../utilities/app.utilities';

export const getParentLinkId = (appLinkData: AppLinkData, appLinkCookies: AppLinkCookies): string => {
  if (appLinkData && appLinkData.parentLinkId) {
    return appLinkData.parentLinkId;
  }
  return appLinkCookies ? appLinkCookies.linkId : null;
};

export const isRecommendationPartOfGoal = (recommendation: RecommendationDto, assessmentGoal: AssessmentGoalDto): boolean => {
  if (!recommendation || !assessmentGoal) {
    return false;
  }
  return recommendation.assessmentGoalVtwId === assessmentGoal.vtwId && recommendation.assessmentGoalType === assessmentGoal.type;
};

export const getRecommendationsFromGoal = (recommendations: RecommendationDto[], assessmentGoal: AssessmentGoalDto): RecommendationDto[] => {
  return recommendations.filter((recommendation) => {
    return isRecommendationPartOfGoal(recommendation, assessmentGoal);
  });
};

export const getAttemptById = (recommendationAttempts: RecommendationAttemptDto[], id: number) => {
  if (!recommendationAttempts) {
    return null;
  }

  return recommendationAttempts.find((attempt) => {
    return attempt.id === Number(id);
  });
};

export const getIncompleteAttempts = (
  recommendationAttempts: RecommendationAttemptDto[],
  recommendation: RecommendationDto
) => {
  if (!recommendationAttempts || !recommendation) {
    return null;
  }
  return recommendationAttempts.filter((attempt) => {
    return attempt.remediationRecommendationId === recommendation.id && attempt.status !== RecommendationAttemptStatusDto.COMPLETED;
  });
};

export const getAssessmentSubmissionByAttempt = (attempt: RecommendationAttemptDto, submissions: AssessmentSubmissionDto[]): AssessmentSubmissionDto => {
  if (!submissions) {
    return null;
  }
  return submissions.find((submission) => {
    return submission.assessmentId === attempt.assessmentId;
  });
};

export const getRecommendationFromAttempt = (recommendations: RecommendationDto[], attempt: RecommendationAttemptDto): RecommendationDto => {
  if (!recommendations || !attempt) {
    return null;
  }
  return recommendations.find((recommendation) => {
    return recommendation.id === attempt.remediationRecommendationId;
  });
};

export const generateRecommendation = (
  contentType: RemRecContentTypeDto,
  assessmentGoalVtwId: string,
  assessmentGoalType: string,
): RecommendationDto => {
  const id = Math.floor(Math.random() * 10000000);
  return {
    id,
    assessmentId: -1,
    userId: -1,
    courseSectionId: -1,
    contentType,
    contentId: 'abc',
    status: 'TODO',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    recommendationData: {
      title: `Recommendation example title for ${contentType} (topic: ${assessmentGoalVtwId} / id:${id})`,
      timeEstimateSeconds: 500,
      topics: ['Topic 1', 'Topic 2'],
      skipReason: null,
      subtitle: 'This is an example longer form description of the content item'
    },
    remediationAssignmentId: null,
    createdAt: null,
    updatedAt: null,
    assessmentGoalVtwId,
    assessmentGoalType,
  };
};

export const generateMockAssignment = (): AssignmentDto => {
  return {
    assignmentGoals: [],
    assignmentGradeType: AssignmentGradeType.NOT_GRADED,
    assignmentOptions: [],
    assignmentQuestions: [],
    assignmentScoreType: AssignmentScoreType.BEST,
    assignmentTopics: [],
    assignmentType: AssignmentType.STUDENT_STUDY,
    availableDate: '2024-10-01',
    courseSectionId: null,
    createdAt: null,
    dueDate: moment('2024-12-01').toISOString(),
    elsAssignmentId: 'mockAssignmentId',
    endDate: moment('2021-01-01').toISOString(),
    gradePoints: 100,
    id: 1,
    isbn: null,
    lmsAssignmentGuid: null,
    sendResultToGradebook: false,
    studentAssignments: [],
    students: [],
    studyMode: false,
    targetType: AssignmentTargetType.COURSE,
    title: 'Example Remediation Assignment Title',
    subtitle: null,
    updatedAt: null,
    userId: 1,
    contentId: null
  };
};

export const generateMockRemediationAssignment = (): RemediationAssignmentDto => {
  return {
    id: 1,
    assignmentId: 1,
    createdByUser: 1,
    updatedByUser: 1,
    courseSectionId: 1,
    settings: {
      type: RemediationAssignmentSettingsTypeDto.HESI_EXAM_SETTINGS,
      rootTaxonomyId: null,
      taxonomyNodeLevel: null,
      activityTypes: [{
        activityType: RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE,
        isActive: true,
        recommendationMultiplier: 1,
        recommendationAdder: 0,
        recommendationScoreRanges: [
          {
            minScore: null,
            maxScore: null,
            numOfItems: 1
          }
        ],
        contentSettings: [{
          contentType: RemRecContentTypeDto.RAAS_QUIZ,
          isActive: true,
          minScoreToPass: 0.8,
          recommendationItemScoreRanges: [{
            minScore: null,
            maxScore: null,
            numOfItems: 50
          }]
        }]
      }]
    },
    createdAt: '2021-01-01',
    updatedAt: '2021-01-01'
  };
};

export const generateMockAssessment = (): AssessmentDto => {
  return {
    id: 999,
    assignmentId: 1,
    assessmentGoals: [{
      goal: 3,
      text: 'Anxiety and mood disorder',
      vtwId: 'A',
      type: `${RemediationActivityType.REVIEW}::${RemediationGoalType.TAXON}`,
      optional: false
    }, {
      goal: 2,
      text: 'Another topic here',
      vtwId: 'B',
      type: `${RemediationActivityType.REVIEW}::${RemediationGoalType.TAXON}`,
      optional: false
    }, {
      goal: 3,
      text: 'Case Studies',
      vtwId: RemRecContentTypeDto.HESI_CASE_STUDY,
      type: `${RemediationActivityType.APPLY}::${RemediationGoalType.CONTENT_TYPE}`,
      optional: false
    }, {
      goal: 1,
      text: 'Quiz Questions',
      vtwId: RemRecContentTypeDto.RAAS_QUIZ,
      type: `${RemediationActivityType.ASSESS}::${RemediationGoalType.CONTENT_TYPE}`,
      optional: false
    }, {
      goal: 1,
      text: 'Quiz Questions',
      vtwId: RemRecContentTypeDto.RAAS_QUIZ,
      type: `${RemediationActivityType.ASSESS}::${RemediationGoalType.CONTENT_TYPE}`,
      optional: false
    }],
    assessmentTopics: null,
    studentAssignment: null,
    userId: 1,
    courseSectionId: 1,
    type: AssessmentTypeDto.STUDENT_STUDY,
    lessonId: null,
    studyMode: false,
    isbn: null,
    contentId: null,
    status: AssessmentStatusDto.IN_PROGRESS
  };
};

export const generateRecommendations = (
  checklistItems: AssessmentGoalDto[],
): RecommendationDto[] => {

  return checklistItems.reduce((acc, cur) => {
    let numOfRecs = cur.goal;
    let contentType;
    if (cur.type === `${RemediationActivityType.REVIEW}::${RemediationGoalType.TAXON}`) {
      contentType = RemRecContentTypeDto.EBOOK_READING;
      numOfRecs += 5;
    }
    if (cur.type === `${RemediationActivityType.APPLY}::${RemediationGoalType.CONTENT_TYPE}`) {
      contentType = RemRecContentTypeDto.HESI_CASE_STUDY;
    }
    if (cur.type === `${RemediationActivityType.ASSESS}::${RemediationGoalType.CONTENT_TYPE}`) {
      contentType = RemRecContentTypeDto.RAAS_QUIZ;
    }
    const recommendations = Array.from({ length: numOfRecs }, () => {
      return generateRecommendation(
        contentType,
        cur.vtwId,
        cur.type
      );
    });
    return acc.concat(recommendations);
  }, []);

};

export const generateRecommendationItems = (
  recommendations: RecommendationDto[],
): RecommendationItemDto[] => {
  return recommendations.reduce((acc, rec) => {
    if (rec.contentType !== RemRecContentTypeDto.RAAS_QUIZ) {
      return acc;
    }
    const items = Array.from({ length: 60 }, () => {
      return {
        id: Math.floor(Math.random() * 10000000),
        itemType: RemRecItemTypeDto.EAQ_QUESTION,
        itemId: 'abc',
        itemData: null,
        remediationRecommendation: rec,
        createdAt: null,
        updatedAt: null
      };
    });
    return [...acc, ...items];
  }, []);
};

const getItemsFromRecommendation = (recommendationId: number, config: StatusMapConfig): RecommendationItemDto[] => {
  return config.recommendationItems.filter((item) => {
    return item.remediationRecommendationId === recommendationId;
  });
};

const getAttemptsFromRecommendation = (recommendationId: number, config: StatusMapConfig): RecommendationAttemptDto[] => {
  return config.recommendationAttempts.filter((item) => {
    return item.remediationRecommendationId === recommendationId;
  });
};

const getResultsFromRecommendationAttempt = (recommendationAttemptId: number, config: StatusMapConfig): QuestionSubmissionDetailsDto[] => {
  return config.quizSessionResults.filter((item) => {
    return item.recommendationAttemptId === recommendationAttemptId;
  });
};
export const getTotalCompleted = (recommendation: RecommendationDto, config: StatusMapConfig): number => {
  const attempts = getAttemptsFromRecommendation(recommendation.id, config);
  return attempts.reduce((acc, attempt) => {
    // TODO: Think about how to capture completed and passed threshold
    if (
      attempt
      && attempt.status === RecommendationAttemptStatusDto.COMPLETED
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

export const getTotalAttempted = (recommendation: RecommendationDto, config: StatusMapConfig): number => {
  const attempts = getAttemptsFromRecommendation(recommendation.id, config);
  return attempts.reduce((acc, attempt) => {
    if (
      attempt
      && attempt.status === RecommendationAttemptStatusDto.COMPLETED
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

export const generateRecommendationStatusMap = (
  config: StatusMapConfig
): RecommendationStatusMap => {

  if (!config) {
    return null;
  }

  return config.recommendations.reduce((acc, recommendation) => {
    return {
      ...acc,
      [recommendation.id]: {
        completed: getTotalCompleted(recommendation, config),
        total: 1,
        attempted: getTotalAttempted(recommendation, config),
        label: RemediationStatusCountLabel.COMPLETED,
      }
    };
  }, {});

};

export const getTotalItemCorrect = (item: RecommendationItemDto, config: StatusMapConfig): number => {
  const attempts = getAttemptsFromRecommendation(item.remediationRecommendationId, config);

  if (!attempts) {
    return 0;
  }
  return attempts.reduce((acc, attempt) => {
    return getResultsFromRecommendationAttempt(attempt.id, config).reduce((_acc, result) => {
      return result.questions.reduce((__acc, question) => {
        if (question.questionVtwId === item.itemId && question.correct) {
          return __acc + 1;
        }
        return __acc;
      }, _acc);
    }, acc);
  }, 0);
};

export const getTotalItemAttempted = (item: RecommendationItemDto, config: StatusMapConfig): number => {
  const attempts = getAttemptsFromRecommendation(item.remediationRecommendationId, config);

  if (!attempts) {
    return 0;
  }
  return attempts.reduce((acc, attempt) => {
    return getResultsFromRecommendationAttempt(attempt.id, config).reduce((_acc, result) => {
      return result.questions.reduce((__acc, question: QuizSessionQuestionDto) => {
        if (question.questionVtwId === item.itemId) {
          return __acc + 1;
        }
        return __acc;
      }, _acc);
    }, acc);
  }, 0);
};

export const generateRecommendationItemStatusMap = (
  config: StatusMapConfig
): RecommendationItemStatusMap => {

  if (!config) {
    return null;
  }

  return config.recommendations.reduce((acc, recommendation) => {

    const items = getItemsFromRecommendation(recommendation.id, config);

    if (!items) {
      return {
        ...acc,
        [recommendation.id]: null
      };
    }

    return {
      ...acc,
      [recommendation.id]: items.reduce((_acc, item) => {

        const totals: RemediationStatusCounts = {
          completed: getTotalItemCorrect(item, config) > 0 ? 1 : 0,
          total: 1,
          attempted: getTotalItemAttempted(item, config) > 0 ? 1 : 0,
          label: RemediationStatusCountLabel.CORRECT,
          goal: null
        };

        return {
          ..._acc,
          [item.id]: totals
        };
      }, {})
    };
  }, {});

};

export const getHourAndMinuteFromSecond = (second: number): string => {
  if (second === 0) {
    return '0 min';
  }

  let minute = Math.floor(second / 60);
  let hour = 0;

  if (minute === 0) {
    return '1 min';
  }

  if (minute >= 60) {
    hour = Math.floor(minute / 60);
    minute -= 60 * hour;

    if (minute === 0) {
      return `${hour} hr`;
    }

    return `${hour} hr ${minute} min`;
  }

  return `${minute} min`;
};

export const getActivityTypeFromAssessmentGoalType = (string): RemediationActivityType => {
  return string.split('::')[0] as RemediationActivityType;
};

export const getActivityTypeFromRecommendation = (recommendation: RecommendationDto): RemediationActivityType => {
  if (!recommendation) {
    return null;
  }
  return getActivityTypeFromAssessmentGoalType(recommendation.assessmentGoalType);
};

export const getActivityTypeFromAssessmentGoal = (assessmentGoal: AssessmentGoalDto): RemediationActivityType => {
  return getActivityTypeFromAssessmentGoalType(assessmentGoal.type);
};

export const getAssessmentGoalsTotals = (
  props: {
    assessmentGoals: AssessmentGoalDto[];
    activityType: RemediationActivityType;
    baseState: RemediationBaseState;
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
): RemediationStatusCounts => {

  const {
    assessmentGoals,
    activityType,
    baseState,
  } = props;

  const defaultTotal: RemediationStatusCounts = {
    total: 0,
    completed: 0,
    attempted: 0,
    goal: 0,
    label: RemediationStatusCountLabel.COMPLETED
  };

  if (!baseState || !assessmentGoals) {
    return defaultTotal;
  }

  return assessmentGoals.reduce((acc, assessmentGoal: AssessmentGoalDto) => {

    if (!assessmentGoal) {
      return acc;
    }

    if (getActivityTypeFromAssessmentGoal(assessmentGoal) !== activityType) {
      return acc;
    }

    return baseState.recommendations.reduce((_acc, rec) => {

      if (RemediationContentTypeConfigMap[rec.contentType].activityType !== activityType) {
        return _acc;
      }

      if (rec.assessmentGoalVtwId !== assessmentGoal.vtwId) {
        return _acc;
      }

      if (!baseState.recommendationStatusMap) {
        return _acc;
      }

      // const status = getStatusCounts(rec)
      const status = baseState.recommendationStatusMap[rec.id];

      if (!status) {
        return _acc;
      }

      const completedAdder = status.completed > 0 ? 1 : 0;

      const newCompleted = _acc.completed + completedAdder;
      const newAttempted = _acc.attempted + status.attempted;

      return {
        ..._acc,
        total: _acc.total,
        completed: newCompleted < _acc.total ? newCompleted : _acc.total,
        attempted: newAttempted,
        label: RemediationStatusCountLabel.COMPLETED
      };
    }, {
      ...acc,
      total: acc.total + assessmentGoal.goal,
      goal: acc.total + assessmentGoal.goal
    });
  }, defaultTotal);
};

export const getQuizPassNumber = (remediationAssignment: RemediationAssignmentDto, items: RecommendationItemDto[]): number => {

  if (!items || !items.length) {
    return null;
  }

  if (!remediationAssignment || !remediationAssignment.settings || !remediationAssignment.settings.activityTypes) {
    return items.length;
  }

  const assessActivityTypeConfig = remediationAssignment.settings.activityTypes.find((activityType: HesiExamRemediationSettingsDto['activityTypes'][0]) => {
    return activityType.activityType === RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE;
  });

  if (!assessActivityTypeConfig) {
    return items.length;
  }

  const contentSettingConfig = assessActivityTypeConfig.contentSettings.find((contentSetting) => {
    return contentSetting.contentType === RemRecContentTypeDto.RAAS_QUIZ;
  });

  if (!contentSettingConfig) {
    return items.length;
  }

  return Math.round(contentSettingConfig.minScoreToPass * items.length);

};

export const getContentTypePassNumber = (recommendation: RecommendationDto, baseState: RemediationBaseState): number => {

  const {
    remediationAssignment,
    recommendationItems
  } = baseState;

  if (!recommendation) {
    return null;
  }

  if (!remediationAssignment || !remediationAssignment.settings || !remediationAssignment.settings.activityTypes) {
    return null;
  }

  if (recommendation.contentType === RemRecContentTypeDto.RAAS_QUIZ) {
    return getQuizPassNumber(remediationAssignment, recommendationItems);
  }

  const activityTypeConfig = remediationAssignment.settings.activityTypes.find((_activityTypeConfig: HesiExamRemediationSettingsDto['activityTypes'][0]) => {
    // TODO: think more about this
    return _activityTypeConfig.activityType.includes(RemediationContentTypeConfigMap[recommendation.contentType].activityType);
  });

  if (!activityTypeConfig) {
    return null;
  }

  const contentSettingConfig = activityTypeConfig.contentSettings.find((contentSetting) => {
    return contentSetting.contentType === recommendation.contentType;
  });

  if (!contentSettingConfig) {
    return null;
  }

  return contentSettingConfig.minScoreToPass;

};

export const getAssessmentGoalsItemsTotals = (
  props: {
    assessmentGoals: AssessmentGoalDto[];
    activityType: RemediationActivityType;
    baseState: RemediationBaseState;
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
): RemediationStatusCounts => {

  const {
    assessmentGoals,
    activityType,
    baseState
  } = props;

  const defaultTotal: RemediationStatusCounts = {
    total: 0,
    completed: 0,
    attempted: 0,
    goal: 0,
    label: RemediationStatusCountLabel.CORRECT
  };

  if (!assessmentGoals) {
    return defaultTotal;
  }

  return assessmentGoals.reduce((acc, assessmentGoal) => {

    if (!assessmentGoal) {
      return acc;
    }

    if (getActivityTypeFromAssessmentGoal(assessmentGoal) !== activityType) {
      return acc;
    }

    return baseState.recommendations.reduce((_acc, rec) => {

      if (!rec) {
        return _acc;
      }

      if (RemediationContentTypeConfigMap[rec.contentType].activityType !== activityType) {
        return _acc;
      }

      if (!isRecommendationPartOfGoal(rec, assessmentGoal)) {
        return _acc;
      }

      if (!baseState.recommendationItems) {
        return _acc;
      }

      const items = baseState.recommendationItems.filter((item) => {
        return item.remediationRecommendationId && item.remediationRecommendationId === rec.id;
      });

      return items.reduce((__acc, item) => {

        const status = baseState.recommendationItemStatusMap[rec.id][item.id];

        if (!status) {
          return __acc;
        }

        const newCompleted = __acc.completed + status.completed;
        const newAttempted = __acc.attempted + status.attempted;

        return {
          ...__acc,
          total: __acc.total,
          completed: newCompleted < __acc.total ? newCompleted : __acc.total,
          attempted: newAttempted,
          label: RemediationStatusCountLabel.CORRECT
        };
      }, {
        ..._acc,
        total: _acc.total + items.length,
        goal: getQuizPassNumber(baseState.remediationAssignment, baseState.recommendationItems)
      });

    }, acc);
  }, defaultTotal);
};

export const getAssessmentGoalsTotalsCombined = (
  props: {
    assessmentGoals: AssessmentGoalDto[];
    baseState: RemediationBaseState;
    activityType: RemediationActivityType;
  }
): RemediationStatusCounts => {

  const {
    assessmentGoals,
    baseState,
    activityType
  } = props;

  if (props.activityType === RemediationActivityType.ASSESS) {
    return getAssessmentGoalsItemsTotals(
      {
        assessmentGoals,
        baseState,
        activityType,
      }
    );
  }

  return getAssessmentGoalsTotals({
    assessmentGoals,
    activityType,
    baseState
  });
};

export const getProgressDisplay = (totals: RemediationStatusCounts): string => {
  return `${totals.completed}/${totals.total} ${totals.label}`;
};

export const getContentTypesFromActivityType = (activityType: RemediationActivityType): RemRecContentTypeDto[] => {
  return Object.keys(RemRecContentTypeDto).filter((contentType: RemRecContentTypeDto) => {
    return RemediationContentTypeConfigMap[contentType] && RemediationContentTypeConfigMap[contentType].activityType === activityType;
  }) as RemRecContentTypeDto[];
};

export const getContentTypeDisplay = (contentTypes: RemRecContentTypeDto[]) => {
  return orderBy(contentTypes, (contentType) => RemediationContentTypeConfigMap[contentType].sortOrder)
    .map((contentType: RemRecContentTypeDto) => {
      return RemediationContentTypeConfigMap[contentType].titlePlural;
    }).join(' & ');
};

export const getGoalTypeFromAssessmentGoal = (assessmentGoal: AssessmentGoalDto): RemediationGoalType => {
  if (!assessmentGoal) {
    return null;
  }
  return assessmentGoal.type.split('::')[1] as RemediationGoalType;
};

export const getContentTypesFromAssessmentGoal = (assessmentGoal: AssessmentGoalDto): RemRecContentTypeDto[] => {
  const activityType = getActivityTypeFromAssessmentGoal(assessmentGoal);
  return getContentTypesFromActivityType(activityType);
};

export const getRecommendationStatus = (props: {
  recommendationAttempts: RecommendationAttemptDto[];
  recommendation: RecommendationDto;
}): RecommendationAttemptStatusDto => {
  const {
    recommendationAttempts,
    recommendation
  } = props;
  if (!recommendation || !recommendationAttempts) {
    return RecommendationAttemptStatusDto.NOT_STARTED;
  }
  const attempts = recommendationAttempts
    .filter((attempt) => {
      return attempt.remediationRecommendationId === recommendation.id;
    });

  if (!attempts.length) {
    return RecommendationAttemptStatusDto.NOT_STARTED;
  }

  const completedAttempt = attempts
    .find((attempt) => {
      return attempt.status === RecommendationAttemptStatusDto.COMPLETED;
    });
  if (completedAttempt) {
    return RecommendationAttemptStatusDto.COMPLETED;
  }
  return RecommendationAttemptStatusDto.IN_PROGRESS;
};

export const getStatusFromTotals = (totals: RemediationStatusCounts): RecommendationAttemptStatusDto => {
  if (totals.total >= totals.goal) {
    return RecommendationAttemptStatusDto.COMPLETED;
  }

  if (totals.total === 0) {
    return RecommendationAttemptStatusDto.NOT_STARTED;
  }

  return RecommendationAttemptStatusDto.IN_PROGRESS;
};

export const getQuizSessionResults = (
  baseState: RemediationBaseState,
  outPostBody: AssessmentPlayerAppLinkOutBody
): {
  totalQuestions: number;
  totalCorrect: number;
} => {

  if (!baseState || !baseState.quizSessionResults) {
    return null;
  }

  if (!outPostBody.quizSessionId) {
    return null;
  }

  const {
    quizSessionId
  } = outPostBody;

  const { quizSessionResults } = baseState;

  if (!quizSessionResults || !quizSessionResults.length) {
    return null;
  }

  const quizSessionResult = quizSessionResults.find((result) => {
    return result.quizSessionId === quizSessionId;
  });

  if (!quizSessionResult) {
    return null;
  }
  const totalQuestions = quizSessionResult.questions.length;
  const totalCorrect = quizSessionResult.questions.filter((question) => question.correct).length;
  return {
    totalQuestions,
    totalCorrect
  };
};

export const isQuizComplete = (statusCounts: RemediationStatusCounts): boolean => {
  if (!statusCounts) {
    return false;
  }
  return statusCounts.goal <= statusCounts.completed && statusCounts.total <= statusCounts.attempted;
};

export const isGoalComplete = (statusCounts: RemediationStatusCounts) => {
  if (!statusCounts || !statusCounts.goal) {
    return false;
  }
  return statusCounts.completed >= statusCounts.goal;
};

export const getGoalFromRecommendation = (assessmentGoals: AssessmentGoalDto[], recommendation: RecommendationDto): AssessmentGoalDto => {
  if (!recommendation || !assessmentGoals) {
    return null;
  }
  return assessmentGoals.find((goal) => {
    return goal.vtwId === recommendation.assessmentGoalVtwId && goal.type === recommendation.assessmentGoalType;
  });
};

export const getAssessmentGoalFromRecommendation = (assessment: AssessmentDto, recommendation: RecommendationDto): AssessmentGoalDto => {
  if (!assessment) {
    return null;
  }
  return getGoalFromRecommendation(assessment.assessmentGoals, recommendation);
};

export const getQuizRecommendationAttemptProgress = (
  quizSessionResults: QuestionSubmissionDetailsDto[],
  quizItems: RecommendationItemDto[]
): {
  totalAttempted: number;
  totalCorrect: number;
  totalQuestions: number;
} => {

  if (!quizSessionResults || !quizSessionResults.length) {
    return null;
  }

  if (!quizItems || !quizItems.length) {
    return null;
  }

  const resultsMap: Record<string, QuizSessionQuestionDto> = quizSessionResults.reduce((acc, quizSessionResult) => {
    return quizSessionResult.questions.reduce((_acc, question: QuizSessionQuestionDto) => {
      if (_acc[question.questionVtwId] && _acc[question.questionVtwId].correct) {
        return _acc;
      }
      return {
        ..._acc,
        [question.questionVtwId]: question
      };
    }, acc);
  }, {});

  const questionsAnswered = Object.values(resultsMap);

  return {
    totalQuestions: quizItems.length,
    totalAttempted: questionsAnswered.length,
    totalCorrect: questionsAnswered.filter((question) => {
      return question.correct;
    }).length
  };
};

type NavigateToContentProps = {
  navigateToApp: NavigateToApp;
  recommendation: RecommendationDto;
  recommendationAttempt: RecommendationAttemptDto;
  appLinkData: AppLinkData;
  appLinkCookies: AppLinkCookies;
  assessment: AssessmentDto;
  quizSession: QuizSessionDto;
  redirect: (path: string) => void;
}
const handleNavigateToCaseStudyApp = (props: NavigateToContentProps) => {

  const {
    navigateToApp,
    recommendation,
    appLinkData,
    appLinkCookies,
    recommendationAttempt,
    assessment
  } = props;

  const outPostBody: CaseStudyAppLinkOutBody = {
    assessmentVtwId: recommendation.contentId,
    instanceId: `RAAS_ATTEMPT_${recommendationAttempt.id}`,
    ref: RoutePath.REMEDIATION_BASE,
    raasAssessmentId: assessment.id,
    recommendationId: props.recommendation.id,
    recommendationAttemptId: recommendationAttempt.id,
  };

  navigateToApp({
    action: AppAction.ASSESSMENT_START,
    app: Application.CASE_STUDY_APP,
    body: outPostBody,
    altSrcApp: Application.STUDENT_STUDY,
    includeLinkHash: true,
    parentLinkId: getParentLinkId(appLinkData, appLinkCookies)
  });
};

const handleNavigateToQuizApp = (props: NavigateToContentProps) => {
  const {
    quizSession,
    recommendation,
    recommendationAttempt,
    navigateToApp,
    appLinkData,
    appLinkCookies,
    assessment
  } = props;

  const outPostBody: AssessmentPlayerAppLinkOutBody = {
    ref: RoutePath.REMEDIATION_BASE,
    quizSessionId: quizSession.id,
    assessmentId: quizSession.eolsAssessmentId,
    raasAssessmentId: assessment.id,
    recommendationId: recommendation.id,
    recommendationAttemptId: recommendationAttempt.id,
    getNextQuestionAPI: `${ELSCommonConfig.buildUrl}/api/florence/remediation/quiz-session/next-question?assessmentId=${quizSession.eolsAssessmentId}`,
    submitQuestionAPI: `${ELSCommonConfig.buildUrl}/api/florence/remediation/quiz-session/submit-question`,
    assessmentPlayerConfig: {
      navigationType: 'Submit-Continue', // 'Next-Back',
      strictStudyMode: true
    }
  };

  navigateToApp({
    action: AppAction.ASSESSMENT_START,
    app: Application.NHE_ASSESSMENT_PLAYER,
    body: outPostBody,
    includeLinkHash: true,
    parentLinkId: getParentLinkId(appLinkData, appLinkCookies)
  });
};
export const handleNavigateToContent = (props: NavigateToContentProps) => {
  const {
    recommendation,
    recommendationAttempt,
    redirect
  } = props;

  if (recommendation.contentType === RemRecContentTypeDto.RAAS_QUIZ) {
    handleNavigateToQuizApp(props);
    return;
  }

  if (recommendation.contentType === RemRecContentTypeDto.HESI_CASE_STUDY) {
    handleNavigateToCaseStudyApp(props);
  }

  if (recommendation.contentType === RemRecContentTypeDto.OSMOSIS_VIDEO) {
    redirect(addSearchParams(RoutePath.OSMOSIS_PLAYER, {
      recommendationAttemptId: recommendationAttempt.id,
      recommendationId: recommendationAttempt.remediationRecommendationId,
    }));
  }

  if (recommendation.contentType === RemRecContentTypeDto.EBOOK_READING) {
    redirect(addSearchParams(RoutePath.EBOOK_PLAYER, {
      recommendationAttemptId: recommendationAttempt.id,
      recommendationId: recommendationAttempt.remediationRecommendationId,
    }));
  }

};

export const sortAssessmentGoals = (assessmentGoals: AssessmentGoalDto[]) => {
  const goalTypeOrder: Record<RemediationActivityType, number> = {
    [RemediationActivityType.REVIEW]: 1,
    [RemediationActivityType.APPLY]: 2,
    [RemediationActivityType.ASSESS]: 3
  };
  return orderBy(assessmentGoals, [(goal) => {
    return goalTypeOrder[getActivityTypeFromAssessmentGoal(goal)];
  }, 'goal', 'text'], ['asc', 'desc', 'asc']);
};

export const sortRecommendations = (recommendations: RecommendationDto[], assessmentGoals: AssessmentGoalDto[]): RecommendationDto[] => {
  const sortedGoals = sortAssessmentGoals(assessmentGoals);
  return orderBy(recommendations, [(recommendation) => {
    return sortedGoals.findIndex((goal) => {
      return isRecommendationPartOfGoal(recommendation, goal);
    });
  }, (recommendation) => {
    return recommendation.id;
  }], ['asc', 'asc']);
};

export const getIncompleteRecommendations = (
  recommendationStatusMap: RecommendationStatusMap,
  recommendations: RecommendationDto[]
): RecommendationDto[] => {
  const incompleteRecommendationKeys: number[] = Object.keys(recommendationStatusMap).reduce((acc, key) => {
    if (recommendationStatusMap[key].completed >= recommendationStatusMap[key].total) {
      return acc;
    }
    return [...acc, parseInt(key, 10)];
  }, []);

  if (!incompleteRecommendationKeys || !incompleteRecommendationKeys.length) {
    return null;
  }
  return recommendations.filter((recommendation) => {
    return incompleteRecommendationKeys.includes(recommendation.id);
  });
};

export const getNextRecommendation = (baseState: RemediationBaseState, assessmentGoal: AssessmentGoalDto) => {
  if (!baseState || !baseState.recommendations || !baseState.assessment) {
    return null;
  }

  const incompleteGoals = baseState.assessment.assessmentGoals.filter((goal) => {

    const activityType = getActivityTypeFromAssessmentGoal(goal);
    const statusCounts = getAssessmentGoalsTotalsCombined({
      assessmentGoals: [goal],
      activityType,
      baseState,
    });

    if (activityType === RemediationActivityType.ASSESS) {
      return !isQuizComplete(statusCounts);
    }
    return !isGoalComplete(statusCounts);
  });

  if (!incompleteGoals || !incompleteGoals.length) {
    return null;
  }

  const incompleteRecommendations = getIncompleteRecommendations(baseState.recommendationStatusMap, baseState.recommendations);

  if (!incompleteRecommendations || !incompleteRecommendations.length) {
    return null;
  }

  const incompleteRecommendationsByGoal = incompleteRecommendations.filter((recommendation) => {
    return getGoalFromRecommendation(incompleteGoals, recommendation);
  });

  if (!incompleteRecommendationsByGoal || !incompleteRecommendationsByGoal.length) {
    return null;
  }

  if (assessmentGoal) {
    const currentGoalRecommendations = incompleteRecommendationsByGoal.filter((recommendation) => {
      return isRecommendationPartOfGoal(recommendation, assessmentGoal);
    });
    if (currentGoalRecommendations && currentGoalRecommendations.length) {
      return sortRecommendations(currentGoalRecommendations, baseState.assessment.assessmentGoals)[0];
    }
  }

  // TODO: Consider sort
  return sortRecommendations(incompleteRecommendationsByGoal, baseState.assessment.assessmentGoals)[0];
};

export const getRecommendationContentId = (recommendation: RecommendationDto): string => {
  if (!recommendation) {
    return null;
  }
  if (recommendation.contentType === RemRecContentTypeDto.RAAS_QUIZ) {
    // TODO Fix me
    return null;
  }
  if (recommendation.contentType === RemRecContentTypeDto.OSMOSIS_VIDEO) {
    return getOsmosisVideoIdFromRecommendation(recommendation);
  }
  if (recommendation.contentType === RemRecContentTypeDto.EBOOK_READING) {
    return getBookIdFromRecommendation(recommendation);
  }
  return recommendation.contentId;
};

export const getRecommendationTitle = (recommendation: RecommendationDto): string => {
  if (!recommendation || !recommendation.recommendationData) {
    return null;
  }
  if (recommendation.contentType === RemRecContentTypeDto.RAAS_QUIZ) {
    // TODO Fix me
    return null;
  }
  if (recommendation.contentType === RemRecContentTypeDto.OSMOSIS_VIDEO) {
    const data = recommendation.recommendationData as unknown as RecommendationDataOsmosisVideoDto;
    return data.source.document_title;
  }
  if (recommendation.contentType === RemRecContentTypeDto.EBOOK_READING) {
    const data = recommendation.recommendationData as unknown as RecommendationDataBookDto;
    return data.source.document_title;
  }
  return recommendation.recommendationData.title;
};

export const getRecommendationEstimatedTime = (recommendation: RecommendationDto): number => {
  if (!recommendation || !recommendation.recommendationData) {
    return null;
  }
  if (recommendation.contentType === RemRecContentTypeDto.RAAS_QUIZ) {
    // TODO Fix me
    return null;
  }
  if (recommendation.contentType === RemRecContentTypeDto.OSMOSIS_VIDEO) {
    // const data = recommendation.recommendationData as unknown as RecommendationDataOsmosisVideoDto;
    return 0;
  }
  if (recommendation.contentType === RemRecContentTypeDto.EBOOK_READING) {
    // const data = recommendation.recommendationData as unknown as RecommendationDataBookDto;
    return 0;
  }
  return null;
};

export const getNewAttemptAssessment = (
  recommendation: RecommendationDto
): Partial<AssessmentDto> => {
  const contentId = getRecommendationContentId(recommendation);
  if (!recommendation || !contentId) {
    return null;
  }
  return {
    assessmentGoals: [{
      goal: 1,
      text: recommendation.contentType,
      vtwId: contentId
    }],
    assessmentTopics: [],
    courseSectionId: recommendation.courseSectionId,
    type: AssessmentTypeDto.STUDENT_STUDY,
    contentId
  };
};
