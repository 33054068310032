import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
import {
  AdminChatIsbnBookDto,
  ChatDto,
  ChatEntitlementDto,
  ChatEntitlementTypeDto,
  ChatEntryDto,
  ChatEntryEvaluationDto,
  ChatEntryTraceDto,
  ChatPaginationResponseDto,
  ChatPerformanceDto,
  ChatReferenceDto,
  DehydratedChatEntryDto,
  LlmPromptDto,
  LlmResponseDto,
  NewQuizSessionDto,
  QuizSessionDto,
  QuestionSubmissionDetailsDto,
  RecommendationAttemptDto,
  RecommendationDto,
  RecommendationItemDto,
  RemediationAssignmentDto,
  StudentThemesStatusDto,
  VectorSearchDto,
  VectorSearchResponseDto,
  QuizAttemptDetailsDto,
} from './florence-facade.dtos';

export const fetchChats = (): Promise<ChatPaginationResponseDto & {
  content: ChatDto[];
}> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/florence/chats', null));
  });
};

export const deleteChats = (): Promise<void> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.delete('/florence/chats');
  });
};

export const postChat = (chat: Omit<ChatDto, 'id'>): Promise<ChatDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/florence/chat', { data: chat });
  });
};

export const putChat = (chat: ChatDto): Promise<ChatDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put(`/florence/chat/${chat.id}`, { data: chat });
  });
};

export const fetchChatEntries = (chatId: string): Promise<ChatPaginationResponseDto & {
  content: ChatEntryDto[];
}> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/florence/chat/${chatId}/entries`);
  });
};

export const postChatEntry = (entry: DehydratedChatEntryDto, chatId: number): Promise<ChatEntryDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/florence/chat/${chatId}/entry`, { data: entry });
  });
};

export const postChatPrompt = (entry: DehydratedChatEntryDto, chatId: number): Promise<ChatEntryDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/florence/chat/${chatId}/prompt`, { data: entry });
  });
};

export const postChatEntryShells = (entry: DehydratedChatEntryDto, chatId: number): Promise<ChatEntryDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/florence/chat/${chatId}/entry/shells`, { data: entry });
  });
};

export const putChatEntry = (entry: ChatEntryDto): Promise<ChatEntryDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/florence/chat/${entry.chatId}/entry/${entry.id}`, { data: entry });
  });
};

export const fetchChatEntry = (chatId: number, entryId: number): Promise<ChatEntryDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/florence/chat/${chatId}/entry/${entryId}`);
  });
};

export const fetchChatEntryTraces = (chatId: number, entryId: number): Promise<ChatEntryTraceDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/florence/chat/${chatId}/entry/${entryId}/traces`);
  });
};

export const fetchAndPostProcessChatEntryReferences = (botEntryId: number): Promise<ChatReferenceDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/florence/chat/post-process/references', { botEntryId }));
  });
};

export const fetchAndPostProcessChatEntryReferencesV2 = (botEntryId: number): Promise<ChatEntryDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/florence/chat/post-process', { botEntryId }));
  });
};

export const putPostProcessChatEntryReferences = (botEntryId: number, postProcessOverrideDto: ChatEntryDto['promptOverride']): Promise<ChatEntryDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put(addSearchParams('/florence/chat/post-process', { botEntryId }), { data: postProcessOverrideDto });
  });
};

export const deleteChatEntry = (chatId: string, entryId: string): Promise<void> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.delete(`/florence/chat/${chatId}/entry/${entryId}`);
  });
};

export const postChatEvaluation = (chatId: number, evaluation: Partial<ChatEntryEvaluationDto>): Promise<ChatEntryEvaluationDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/florence/chat/${chatId}/entry/${evaluation.entryId}/evaluation`, { data: evaluation });
  });
};

export const putChatEvaluation = (chatId: number, evaluation: ChatEntryEvaluationDto): Promise<ChatEntryEvaluationDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put(`/florence/chat/${chatId}/entry/${evaluation.entryId}/evaluation/${evaluation.id}`, { data: evaluation });
  });
};

export const fetchChatEvaluations = (chatId: string): Promise<ChatEntryEvaluationDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/florence/chat/${chatId}/entry/evaluations`);
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchChatPerformance = (startDate: string, numOfDays: number, courseSectionIds: number[]): Promise<ChatPerformanceDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/florence/student-engagement', {
      data: {
        startDate,
        numOfDays,
        courseSectionIds
      }
    });
  });
};

export const postStudentThemes = (startDate: string, numOfDays: number, courseSectionIds: number[]) => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/florence/student-themes-cache', {
      data: {
        startDate,
        numOfDays,
        courseSectionIds
      }
    });
  });
};

export const fetchStudentThemesStatus = (startDate: string, numOfDays: number, courseSectionIds: number[]): Promise<StudentThemesStatusDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/florence/student-themes/status', { startDate, numOfDays, courseSectionIds }));
  });
};

export const fetchRemediationRecommendations = (eolsAssessmentId?: number, id?: number[]): Promise<RecommendationDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/florence/remediation/recommendation', { eolsAssessmentId, id }));
  });
};

const remediationAttemptPath = '/florence/remediation/recommendation/attempt';
export const fetchRemediationRecommendationAttempts = (remediationRecommendationId: number[]): Promise<RecommendationAttemptDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams(remediationAttemptPath, { remediationRecommendationId }));
  });
};

export const postRemediationRecommendationAttempt = (attempt: Partial<RecommendationAttemptDto>): Promise<RecommendationAttemptDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(addSearchParams(remediationAttemptPath, {}), { data: attempt });
  });
};

export const putRemediationRecommendationAttempt = (attempt: RecommendationAttemptDto): Promise<RecommendationAttemptDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put(addSearchParams('/florence/remediation/recommendation/attempt', {}), { data: attempt });
  });
};

export const fetchRemediationAssignment = (remediationAssignmentId: number): Promise<RemediationAssignmentDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams(`/florence/remediation/assignment/${remediationAssignmentId}`, null));
  });
};

export const fetchRemediationRecommendationItems = (remediationRecommendationId: number): Promise<RecommendationItemDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/florence/remediation/recommendation/items', { remediationRecommendationId }));
  });
};

export const adminSearchVector = (body: VectorSearchDto): Promise<VectorSearchResponseDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/florence/admin/hm-platform/search/vector', { data: body });
  });
};

export const adminPrompt = (body: LlmPromptDto): Promise<LlmResponseDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/florence/admin/hm-platform/prompt', { data: body });
  });
};

export const fetchChatEeoIsbns = (): Promise<string[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get('/florence/chat/eeoisbns');
  });
};

export const fetchAdminChatEeoIsbns = (isbn: string[]): Promise<AdminChatIsbnBookDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/florence/admin/chat/isbn', { isbn }));
  });
};

export const fetchChatEntitlements = (): Promise<Partial<Record<ChatEntitlementTypeDto, ChatEntitlementDto[]>>> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get('/florence/chat/isbn/entitled');
  });
};

export const adminFetchChatEntry = (entryId: string): Promise<ChatEntryDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/florence/admin/entry/${entryId}`);
  });
};

export const adminFetchChatEntries = (chatId: string, page: number, pageSize: number): Promise<ChatPaginationResponseDto & {
  content: ChatEntryDto[];
}> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams(`/florence/admin/chat/${chatId}/entries`, { page, pageSize }));
  });
};

export const adminFetchChatEntryTraces = (entryId: string): Promise<ChatEntryTraceDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/florence/admin/entry/${entryId}/traces`);
  });
};

export const adminFetchChat = (chatId: string): Promise<ChatDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/florence/admin/chat/${chatId}`);
  });
};
export const postRaaSQuizSession = (quizSessionDto: NewQuizSessionDto): Promise<QuizSessionDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/florence/remediation/quiz-session', { data: quizSessionDto });
  });
};

export const fetchQuizSessionById = (quizSessionId: number): Promise<QuestionSubmissionDetailsDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/florence/remediation/quiz-session/question/submissions', { quizSessionId }));
  });
};

export const fetchQuizSessionByRecommendationAttemptId = (recommendationAttemptId: number): Promise<QuizAttemptDetailsDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/florence/remediation/quiz-session/question/submissions', { recommendationAttemptId }));
  });
};
