import React from 'react';
import { StatusPill } from './StatusPill.component';
import { RecommendationAttemptStatusDto } from '../../apis/florence-facade/florence-facade.dtos';

export type TotalsStatusProps = {
  completedGoal: number;
  completed: number;
  total: number;
}
export const TotalsStatus = (props: TotalsStatusProps) => {

  if (props.completed >= props.completedGoal) {
    return (
      <StatusPill status={RecommendationAttemptStatusDto.COMPLETED}>
        {props.completed}/{props.total}
      </StatusPill>
    );
  }

  return (
    <StatusPill status={props.completed > 0 ? RecommendationAttemptStatusDto.IN_PROGRESS : RecommendationAttemptStatusDto.NOT_STARTED}>
      {props.completed}/{props.total}
    </StatusPill>
  );
};
