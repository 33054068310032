import React, {
  useEffect,
  useState
} from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { compose } from 'recompose';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import {
  ELSPropsFromToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import {
  studyActions
} from '../../redux/student-study/studentStudy.actions';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import { locationActions } from '../../redux/location/location.actions';
import {
  RecommendationItemStatusMap,
  RecommendationStatusMap,
} from './remediation-home.models';
import {
  generateRecommendationItemStatusMap,
  generateRecommendationStatusMap,
} from './remediation-home.utilities';
import {
  QuestionSubmissionDetailsDto,
  QuizAttemptDetailsDto,
  RecommendationAttemptDto,
  RecommendationDto,
  RecommendationItemDto,
  RemediationAssignmentDto,
  RemRecContentTypeDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import {
  AssessmentDto,
  AssessmentSubmissionDto,
  AssignmentDto
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { locationSelectors } from '../../redux/location/location.selectors';
import RemediationHome from './RemediationHome.component';
import {
  Application
} from '../../apis/eols-app-link/eols-app-link.constants';
import RecommendationPostPlayer from './RemediationPostPlayer.component';
import RecommendationPostPlayerQuiz from './RemediationPostPlayerQuiz.component';

type RemediationBasePropsOnly = {}

const mapDispatchToProps = {
  returnAppLink: studyActions.returnAppLink,
  trackAction: studyActions.trackAction,
  redirect: locationActions.redirect,
  fetchRemediationRecommendationsAction: studyActions.fetchRemediationRecommendationsAction,
  fetchRemediationRecommendationItemsAction: studyActions.fetchRemediationRecommendationItemsAction,
  fetchRemediationAssignmentAction: studyActions.fetchRemediationAssignmentAction,
  fetchAssessmentByIdAction: studyActions.fetchAssessmentByIdAction,
  fetchAssignmentAction: studyActions.fetchAssignmentAction,
  navigateToApp: studyActions.navigateToApp,
  fetchRemediationRecommendationAttemptsAction: studyActions.fetchRemediationRecommendationAttemptsAction,
  replaceURL: locationActions.replaceURL,
  fetchQuizSessionByRecommendationAttemptIdAction: studyActions.fetchQuizSessionByRecommendationAttemptIdAction,
  postRemediationRecommendationAttemptAction: studyActions.postRemediationRecommendationAttemptAction,
  postRaaSQuizSessionAction: studyActions.postRaaSQuizSessionAction,
  fetchCaseStudyAction: studyActions.fetchCaseStudyAction,
  fetchEolsAssessmentSubmissionsAction: studyActions.fetchEolsAssessmentSubmissionsAction,
};
const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  appLinkData: studySelectors.getLinkData(state),
  appLinkCookies: studySelectors.getAppLinkCookies(state),
  userId: studySelectors.getUserId(state),
  location: locationSelectors.getLocation(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type RemediationBaseProps = PropsFromRedux
  & RemediationBasePropsOnly
  & ELSPropsFromModalService
  & ELSPropsFromToastService;

export type RemediationBaseState = {
  showPageLoader: boolean;
  pendingRequestCount: number;
  remediationAssignment: RemediationAssignmentDto;
  assignment: AssignmentDto;
  assessment: AssessmentDto;
  recommendations: RecommendationDto[];
  recommendationItems: RecommendationItemDto[];
  recommendationAttempts: RecommendationAttemptDto[];
  quizAttemptDetails: Record<string, QuizAttemptDetailsDto>;
  quizSessionResults: QuestionSubmissionDetailsDto[];
  recommendationStatusMap: RecommendationStatusMap;
  recommendationItemStatusMap: RecommendationItemStatusMap;
  attemptAssessmentSubmissions: AssessmentSubmissionDto[];
}

const defaultState: RemediationBaseState = {
  showPageLoader: false,
  pendingRequestCount: 0,
  remediationAssignment: null,
  assignment: null,
  assessment: null,
  recommendations: null,
  recommendationItems: null,
  quizAttemptDetails: null,
  quizSessionResults: null,
  recommendationAttempts: null,
  recommendationStatusMap: null,
  recommendationItemStatusMap: null,
  attemptAssessmentSubmissions: null,
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const RemediationBaseComponent = (props: RemediationBaseProps) => {

  const [state, setState] = useState<RemediationBaseState>(defaultState);

  const mergeState = (newState: Partial<RemediationBaseState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  const initFromApis = () => {
    const {
      fetchRemediationRecommendationsAction,
      fetchRemediationRecommendationItemsAction,
      appLinkData,
      userId,
      fetchAssessmentByIdAction,
      fetchRemediationAssignmentAction,
      fetchAssignmentAction,
      fetchRemediationRecommendationAttemptsAction,
      fetchQuizSessionByRecommendationAttemptIdAction,
      fetchEolsAssessmentSubmissionsAction
    } = props;

    const assessmentId = appLinkData.targetApp === Application.STUDENT_STUDY
      ? appLinkData.outPostBody.assessmentId
      : appLinkData.outPostBody.raasAssessmentId;

    Promise.all([
      fetchAssessmentByIdAction(userId, assessmentId),
      fetchRemediationRecommendationsAction(assessmentId)
    ])

      .then(([remediationAssessment, recommendations]) => {

        if (!recommendations || !recommendations.length) {
          return Promise.resolve({
            remediationAssessment,
            recommendations,
            remediationAssignment: null
          });
        }

        // TODO: Fix this with https://elsevier.atlassian.net/browse/FLOR-1493
        const eolsAssignmentId = remediationAssessment.assignmentId.toString();
        const raasAssignmentId = recommendations[0].remediationAssignmentId;

        fetchAssignmentAction(eolsAssignmentId).then((assignment) => {
          mergeState({ assignment });
        });

        return fetchRemediationAssignmentAction(raasAssignmentId).then((remediationAssignment) => {
          return {
            remediationAssignment,
            remediationAssessment,
            recommendations
          };
        });
      })

      .then(({ remediationAssessment, recommendations, remediationAssignment }) => {

        mergeState({
          assessment: remediationAssessment,
          remediationAssignment,
          recommendations
        });

        if (!recommendations || !recommendations.length) {
          return;
        }

        const recIds = recommendations.map((recommendation) => {
          return recommendation.id;
        });

        const itemsRequest = Promise.all(
          recommendations.map((recommendation) => {
            if (recommendation.contentType !== RemRecContentTypeDto.RAAS_QUIZ) {
              return Promise.resolve(null);
            }
            return fetchRemediationRecommendationItemsAction(recommendation.id);
          })
        );

        Promise.all([
          fetchRemediationRecommendationAttemptsAction(recIds),
          itemsRequest
        ])
          .then((response: [
            RecommendationAttemptDto[],
            RecommendationItemDto[][]
          ]) => {
            const [attempts, items] = response;

            const recommendationItems = items.reduce((acc, cur) => {
              return [
                ...acc,
                ...cur || []
              ];
            }, []);

            const recommendationAttempts: RecommendationAttemptDto[] = attempts.reduce((acc, cur) => {
              if (!cur) {
                return acc;
              }
              return [
                ...acc,
                cur
              ];
            }, []);

            return Promise.all(
              attempts.map((attempt) => {
                const recommendation = recommendations.find((rec) => {
                  return rec.id === attempt.remediationRecommendationId;
                });
                if (!recommendation || recommendation.contentType !== RemRecContentTypeDto.RAAS_QUIZ) {
                  return Promise.resolve(null as QuizAttemptDetailsDto);
                }
                return fetchQuizSessionByRecommendationAttemptIdAction(attempt.id).catch(() => {
                  return null as QuizAttemptDetailsDto;
                });
              })
            ).then((quizAttemptDetailsDtos) => {

              const quizSessionResults: QuestionSubmissionDetailsDto[] = quizAttemptDetailsDtos.reduce((acc, cur) => {
                if (!cur || !cur.questionSubmissionDetails) {
                  return acc;
                }

                return cur.questionSubmissionDetails.reduce((_acc, result) => {
                  return [
                    ..._acc,
                    result
                  ];
                }, acc);
              }, []);

              return {
                quizSessionResults,
                recommendationItems,
                recommendationAttempts
              };
            });

          })
          .then((
            {
              quizSessionResults,
              recommendationItems,
              recommendationAttempts
            }
          ) => {
            const assessmentIds = recommendationAttempts
              .filter((attempt) => {
                return attempt.assessmentId;
              })
              .map((attempt) => {
                return attempt.assessmentId;
              });

            if (!assessmentIds || !assessmentIds.length) {
              return Promise.resolve({
                quizSessionResults,
                recommendationItems,
                recommendationAttempts,
                attemptAssessmentSubmissions: null
              });
            }

            return fetchEolsAssessmentSubmissionsAction(assessmentIds).then((attemptAssessmentSubmissions) => {
              return {
                quizSessionResults,
                recommendationItems,
                recommendationAttempts,
                attemptAssessmentSubmissions
              };
            });
          })
          .then((
            {
              quizSessionResults,
              recommendationItems,
              recommendationAttempts,
              attemptAssessmentSubmissions
            }
          ) => {

            mergeState({
              showPageLoader: false,
              pendingRequestCount: 0,
              attemptAssessmentSubmissions,
              recommendationItems,
              recommendationAttempts,
              quizSessionResults,
              recommendationStatusMap: generateRecommendationStatusMap({
                recommendationItems,
                recommendationAttempts,
                quizSessionResults,
                recommendations
              }),
              recommendationItemStatusMap: generateRecommendationItemStatusMap({
                recommendationItems,
                recommendationAttempts,
                quizSessionResults,
                recommendations
              }),
            });

          });

      });

  };

  useEffect(() => {
    initFromApis();
  }, []);

  const isReturn = (): boolean => {
    const { location } = props;
    return location.query.isReturn && location.query.recommendationAttemptId;
  };

  const isReturnQuiz = () => {
    const { appLinkData } = props;
    return appLinkData.targetApp === Application.NHE_ASSESSMENT_PLAYER;
  };

  if (!state.assignment) {
    return null;
  }

  if (isReturn()) {

    if (isReturnQuiz()) {
      return (
        <RecommendationPostPlayerQuiz baseState={state} />
      );
    }

    return (
      <RecommendationPostPlayer baseState={state} />
    );
  }
  return (
    <RemediationHome baseState={state} />
  );
};

const enhancers = [
  withHTMLHeadSEO({ title: 'Remediation' }),
  connector,
  ELSWithModalService,
  ELSWithToastService
];

const RemediationBase = compose<null, RemediationBasePropsOnly>(...enhancers)(RemediationBaseComponent);

export default RemediationBase;
