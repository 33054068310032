import React from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { compose } from 'recompose';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import { ELSButton } from '@els/els-component-button-react';
import moment from 'moment';
import { isNil } from 'lodash';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { locationActions } from '../../redux/location/location.actions';
import {
  HesiExamRemediationSettingsDto,
  RecommendationAttemptDto,
  RecommendationAttemptStatusDto,
  RecommendationDto,
  RemediationActivityTypeDto,
  RemRecContentTypeDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { locationSelectors } from '../../redux/location/location.selectors';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import {
  ELSButtonIconPosition,
  ELSButtonSize,
  ELSButtonType
} from '../../models/button.models';
import { ELSDataTable } from '../../components/els.components';
import {
  getHourAndMinuteFromSecond,
  getNewAttemptAssessment,
  getRecommendationStatus,
  handleNavigateToContent
} from './remediation-home.utilities';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { DATE_TIME_PRIMARY } from '../../constants/date.constants';
import { RecommendationAttemptStatusDisplayMap } from './remediation-home.constants';
import {
  RemediationContentTypeConfigMap
} from './remediation-home.models';
import { StatusPill } from './StatusPill.component';
import { RemediationBaseState } from './RemediationBase.page';

type RecommendationOverviewPropsOnly = {
  recommendation: RecommendationDto;
  handleReturnNavigation: () => void;
  baseState: RemediationBaseState;
}

const mapDispatchToProps = {
  trackAction: studyActions.trackAction,
  redirect: locationActions.redirect,
  navigateToApp: studyActions.navigateToApp,
  postAssessmentAction: studyActions.postAssessmentAction,
  postRemediationRecommendationAttemptAction: studyActions.postRemediationRecommendationAttemptAction,
  fetchRemediationRecommendationAttemptsAction: studyActions.fetchRemediationRecommendationAttemptsAction,
  fetchCaseStudyAction: studyActions.fetchCaseStudyAction,
  fetchRemediationAssignmentAction: studyActions.fetchRemediationAssignmentAction,
};
const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  appLinkData: studySelectors.getLinkData(state),
  appLinkCookies: studySelectors.getAppLinkCookies(state),
  location: locationSelectors.getLocation(state),
  userId: studySelectors.getUserId(state),
  courseSectionId: studySelectors.getCourseSectionId(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type RecommendationOverviewProps = PropsFromRedux & RecommendationOverviewPropsOnly & ELSPropsFromModalService;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const RecommendationOverviewComponent = (props: RecommendationOverviewProps) => {

  const getRemediationActivityTypeDtoFromRecommendation = (recommendation: RecommendationDto): RemediationActivityTypeDto => {
    if (!recommendation || !recommendation.assessmentGoalType) {
      return null;
    }
    return recommendation.assessmentGoalType.replace('::', '_BY_') as RemediationActivityTypeDto;
  };

  const getScoreThreshold = () => {
    const {
      baseState,
      recommendation
    } = props;

    const {
      remediationAssignment
    } = baseState;

    if (!remediationAssignment || !recommendation) {
      return null;
    }

    const hesiSettings = remediationAssignment.settings as HesiExamRemediationSettingsDto;

    const activityType = getRemediationActivityTypeDtoFromRecommendation(recommendation);

    const activityTypeConfig = hesiSettings.activityTypes.find((_activityType) => {
      return _activityType.activityType === activityType;
    });
    if (!activityTypeConfig || !activityTypeConfig.contentSettings) {
      return null;
    }
    const contentTypeConfig = activityTypeConfig.contentSettings.find((contentSetting) => {
      return contentSetting.contentType === RemRecContentTypeDto.HESI_CASE_STUDY;
    });

    if (!contentTypeConfig || isNil(contentTypeConfig.minScoreToPass)) {
      return null;
    }
    return `${Math.round(contentTypeConfig.minScoreToPass * 100)}%`;
  };

  const _handleNavigateToContent = (recommendationAttempt: RecommendationAttemptDto) => {
    const {
      recommendation,
      navigateToApp,
      appLinkCookies,
      appLinkData,
      baseState,
      redirect
    } = props;
    handleNavigateToContent({
      recommendationAttempt,
      recommendation,
      assessment: baseState.assessment,
      navigateToApp,
      appLinkCookies,
      appLinkData,
      quizSession: null,
      redirect
    });
  };

  const handleStartNewAttempt = () => {
    const {
      userId,
      postAssessmentAction,
      postRemediationRecommendationAttemptAction,
      recommendation,
    } = props;

    const newAssessment = getNewAttemptAssessment(recommendation);

    if (!newAssessment) {
      // eslint-disable-next-line no-alert
      alert('unable to construct new assessment');
      return;
    }

    postAssessmentAction(userId, newAssessment).then((assessment) => {
      postRemediationRecommendationAttemptAction({
        assessmentId: assessment.id,
        remediationRecommendationId: recommendation.id,
        status: RecommendationAttemptStatusDto.IN_PROGRESS
      }).then((recommendationAttempt) => {
        _handleNavigateToContent(recommendationAttempt);
      });

    });

  };

  const getTableData = () => {
    const {
      baseState,
      recommendation
    } = props;

    const {
      recommendationAttempts,
      attemptAssessmentSubmissions
    } = baseState;

    if (!recommendationAttempts || !recommendation) {
      return null;
    }
    const attempts = recommendationAttempts
      .filter((attempt) => {
        return attempt.remediationRecommendationId === recommendation.id;
      });

    if (!attempts.length) {
      return null;
    }

    return attempts
      .map((attempt) => {

        let submission = null;

        if (attemptAssessmentSubmissions && attemptAssessmentSubmissions.length) {
          submission = attemptAssessmentSubmissions.find((sub) => {
            return sub.assessmentId === attempt.assessmentId;
          });
        }

        return {
          ...attempt,
          score: submission ? submission.score : null,
        };
      });
  };

  const getPerformanceTitle = () => {
    return `${RemediationContentTypeConfigMap[RemRecContentTypeDto.HESI_CASE_STUDY].displayName} performance`;
  };

  const getLastUnfinishedAttempt = () => {
    const {
      baseState,
      recommendation
    } = props;

    const {
      recommendationAttempts
    } = baseState;

    if (!recommendation || !recommendationAttempts || !recommendationAttempts.length) {
      return null;
    }
    return recommendationAttempts
      .filter((attempt) => {
        return attempt.remediationRecommendationId === recommendation.id;
      })
      .find((attempt) => {
        return attempt.status !== RecommendationAttemptStatusDto.COMPLETED;
      });
  };

  const inProgressAttempt = getLastUnfinishedAttempt();

  const getTitle = () => {
    if (!props.recommendation || !props.recommendation.recommendationData) {
      return null;
    }
    return props.recommendation.recommendationData.title;
  };

  const getTime = () => {
    if (!props.recommendation || !props.recommendation.recommendationData) {
      return null;
    }
    return getHourAndMinuteFromSecond(props.recommendation.recommendationData.timeEstimateSeconds);
  };

  const tableData = getTableData();

  const status = getRecommendationStatus({
    recommendation: props.recommendation,
    recommendationAttempts: props.baseState.recommendationAttempts
  });

  return (
    <div className="c-ssa-case-study-overview">

      <div className="o-els-container">
        <FlexLayout
          modifiers={[
            FlexLayoutModifier.GUTTERS
          ]}
        >
          <FlexItem
            modifiers={[
              FlexLayoutModifier.GROW
            ]}
          >
            <button
              type="button"
              className="u-els-anchorize"
              onClick={props.handleReturnNavigation}
            >
              <IconWithText
                iconName="arrow-left"
                iconPrefix="hmds"
                iconPosition={ELSButtonIconPosition.LEFT}
              >
                Back to all recommendations
              </IconWithText>
            </button>
          </FlexItem>
          <FlexItem>
            <StatusPill status={status}>
              {RecommendationAttemptStatusDisplayMap[status]}
            </StatusPill>
          </FlexItem>
        </FlexLayout>

      </div>

      <div className="c-ssa-case-study-overview__body">

        <h2>
          {getTitle()}
        </h2>
        <div className="o-els-container">
          <table className="c-ssa-remediation-goal-modal__goal-table">
            <tbody>
              <tr>
                <td>
                  <IconWithText iconName="syllabus" iconPrefix="hmds">Instructions</IconWithText>
                </td>
                <td>You must reach a score of {getScoreThreshold()} or above on this case study</td>
              </tr>
              <tr>
                <td>
                  <IconWithText iconName="clock" iconPrefix="gizmo">Est. time to complete</IconWithText>
                </td>
                <td>
                  {getTime()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="o-els-container">
          <FlexLayout modifiers={[FlexLayoutModifier.LEFT]}>
            <FlexItem>
              <ELSButton type={ELSButtonType.PRIMARY}
                         size={ELSButtonSize.SMALL}
                         onClick={() => {
                           if (inProgressAttempt) {
                             _handleNavigateToContent(inProgressAttempt);
                             return;
                           }
                           handleStartNewAttempt();
                         }}>
                {inProgressAttempt ? 'Continue latest attempt' : 'Start new attempt'}
              </ELSButton>
            </FlexItem>
          </FlexLayout>
        </div>

        <div className="o-els-container o-els-container--2x c-els-divider" />

        {tableData && (
          <div>
            <h3>{getPerformanceTitle()}</h3>

            <div>
              <ELSDataTable
                data={tableData}
                noWrap
                sortIconSize="1o2"
              >
                <column header="Date started"
                        field="createdAt"
                        sortable
                        customRender={(row: RecommendationAttemptDto) => {
                          return (
                            <span>{moment(row.createdAt).format(DATE_TIME_PRIMARY)}</span>
                          );
                        }}
                />
                <column header="Status"
                        field="status"
                        sortable
                        customRender={(row: RecommendationAttemptDto) => {
                          return (
                            <span>{RecommendationAttemptStatusDisplayMap[row.status]}</span>
                          );
                        }}
                />

                <column header="Score"
                        field="score"
                        sortable
                        customRender={(row) => {
                          if (!row.score) {
                            return '';
                          }
                          return (
                            <span>{`${Math.round(row.score * 100)}%`}</span>
                          );
                        }}
                />

                <column header=" "
                        customRender={(row: RecommendationAttemptDto) => {
                          return (
                            <button
                              type="button"
                              className="u-els-anchorize"
                              onClick={() => {
                                _handleNavigateToContent(row);
                              }}
                            >
                              {row.status === RecommendationAttemptStatusDto.COMPLETED ? 'View results' : 'Continue'}
                            </button>
                          );
                        }}
                />

              </ELSDataTable>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

const enhancers = [
  connector,
  ELSWithModalService
];

const RecommendationOverview = compose<null, RecommendationOverviewPropsOnly>(...enhancers)(RecommendationOverviewComponent);

export default RecommendationOverview;
