import React, {
  useEffect,
  useState
} from 'react';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import {
  ELSDropDown,
  ELSDropDownOption,
  ELSRadio
} from '@els/els-component-form-field-react';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import {
  RecommendationDto,
  RemRecContentTypeDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import {
  ActivityTypeConfigMap,
  RemediationGoalType,
} from './remediation-home.models';
import RemediationRecommendation from './RemediationRecommendation.component';
import {
  getActivityTypeFromAssessmentGoal,
  getAssessmentGoalsTotals,
  getContentTypeDisplay,
  getContentTypesFromAssessmentGoal,
  getGoalTypeFromAssessmentGoal,
  getIncompleteRecommendations,
  getRecommendationEstimatedTime,
  getRecommendationsFromGoal,
  getRecommendationTitle,
  isGoalComplete,
  sortRecommendations,
} from './remediation-home.utilities';
import { AssessmentGoalDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import { ELSButtonIconPosition } from '../../models/button.models';
import RemediationGoalModal from './RemediationGoalModal.component';
import IsRender from '../../components/is-render/IsRender.component';
import { TotalsStatus } from './TotalsStatus.component';
import RecommendationOverview from './RecommendationOverview.component';
import { RemediationBaseState } from './RemediationBase.page';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { locationActions } from '../../redux/location/location.actions';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import {
  CaseStudyResourceDataDto,
  ResourceDto
} from '../../apis/ocs-api-service/ocs-api-service.dtos';
import RemediationCompleteBox from './RemediationCompleteBox.component';

export const RemediationActivityModalId = 'RemediationCategoryModalId';

enum ViewOptionValue {
  TOP_RECOMMENDATION = 'TOP_RECOMMENDATION',
  ALL_RECOMMENDATIONS = 'ALL_RECOMMENDATIONS'
}

const viewOptions: ELSDropDownOption[] = [{
  name: 'Top recommendation',
  value: ViewOptionValue.TOP_RECOMMENDATION
}, {
  name: 'All recommendations',
  value: ViewOptionValue.ALL_RECOMMENDATIONS
}];

enum SkipOptionValue {
  ALREADY = 'ALREADY',
  NO_SENSE = 'NO_SENSE',
  WANT_NEXT = 'WANT_NEXT',
  WANT_OTHER = 'WANT_OTHER',
  OTHER = 'OTHER',
}

const skipOptions: ELSDropDownOption[] = [{
  name: 'I have already completed this activity prior to taking the exam.',
  value: SkipOptionValue.ALREADY
}, {
  name: 'This activity doesn’t make sense for the exam contents.',
  value: SkipOptionValue.NO_SENSE
}, {
  name: 'I want to see the next choice.',
  value: SkipOptionValue.WANT_NEXT
}, {
  name: 'I just want to see all the choices.',
  value: SkipOptionValue.WANT_OTHER
}, {
  name: 'Other',
  value: SkipOptionValue.OTHER
}];

export type RemediationActivityModalPropsOnly = {
  baseState: RemediationBaseState;
  assessmentGoal: AssessmentGoalDto;
}

const mapDispatchToProps = {
  trackAction: studyActions.trackAction,
  fetchCaseStudyAction: studyActions.fetchCaseStudyAction,
  redirect: locationActions.redirect,
  navigateToApp: studyActions.navigateToApp,
};
const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type RemediationActivityModalProps = PropsFromRedux
  & RemediationActivityModalPropsOnly
  & ELSPropsFromModalService;

export type RemediationActivityModalState = {
  activeView: ViewOptionValue;
  activeRecommendationId: number;
  showSkipForm: boolean;
  recommendations: RecommendationDto[];
  selectedRecommendation: RecommendationDto;
}

const defaultState: RemediationActivityModalState = {
  activeView: ViewOptionValue.TOP_RECOMMENDATION,
  activeRecommendationId: null,
  showSkipForm: false,
  recommendations: null, // TODO fix me
  selectedRecommendation: null
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const RemediationActivityModalComponent = (props: RemediationActivityModalProps) => {

  const statusCounts = getAssessmentGoalsTotals(
    {
      assessmentGoals: [props.assessmentGoal],
      activityType: getActivityTypeFromAssessmentGoal(props.assessmentGoal),
      baseState: props.baseState
    }
  );

  const goalRecommendations = getRecommendationsFromGoal(props.baseState.recommendations, props.assessmentGoal);

  const incompleteRecommendations = getIncompleteRecommendations(props.baseState.recommendationStatusMap, goalRecommendations);

  const [state, setState] = useState<RemediationActivityModalState>(
    {
      ...defaultState,
      recommendations: goalRecommendations,
      activeRecommendationId: incompleteRecommendations ? sortRecommendations(
        incompleteRecommendations,
        props.baseState.assessment.assessmentGoals
      )[0].id : null,
      activeView: (statusCounts.completed >= statusCounts.total || goalRecommendations.length === statusCounts.total)
        ? ViewOptionValue.ALL_RECOMMENDATIONS
        : ViewOptionValue.TOP_RECOMMENDATION
    }
  );

  const mergeState = (newState: Partial<RemediationActivityModalState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  // TODO: This is a hack to get the titles - fix it
  const hydrateRecommendations = () => {
    const requests = state.recommendations.map((recommendation): Promise<RecommendationDto> => {
      if (
        recommendation.contentType === RemRecContentTypeDto.HESI_CASE_STUDY
        && recommendation.contentId
      ) {
        return props.fetchCaseStudyAction(recommendation.contentId).then((response: ResourceDto) => {
          try {
            const caseStudyInfo: CaseStudyResourceDataDto = JSON.parse(response.data);
            return {
              ...recommendation,
              recommendationData: {
                ...recommendation.recommendationData,
                title: caseStudyInfo.title,
                timeEstimateSeconds: caseStudyInfo.lessonDuration,
                skipReason: 'test'
              }
            };
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            return recommendation;
          }
        });
      }

      if (recommendation.recommendationData) {
        return Promise.resolve({
          ...recommendation,
          recommendationData: {
            ...recommendation.recommendationData,
            title: getRecommendationTitle(recommendation),
            timeEstimateSeconds: getRecommendationEstimatedTime(recommendation)
          }
        });
      }

      return Promise.resolve(recommendation);
    });

    Promise.all(requests).then((responses) => {
      mergeState({ recommendations: responses });
    });
  };

  useEffect(() => {
    hydrateRecommendations();
  }, []);

  const handleCancelClick = () => {
    props.modalService.closeModal(RemediationActivityModalId);
  };

  const getActiveRecommendation = () => {
    if (!state.recommendations) {
      return null;
    }
    return state.recommendations.find((rec) => {
      return rec.id === state.activeRecommendationId;
    });
  };

  const getActiveRecommendationIndex = () => {
    if (!state.recommendations) {
      return -1;
    }
    return state.recommendations.findIndex((rec) => {
      return rec.id === state.activeRecommendationId;
    });
  };

  const handleSkipSubmit = () => {
    const nextIndex = getActiveRecommendationIndex() + 1;
    const nextRec = state.recommendations[nextIndex] || state.recommendations[0];
    mergeState({
      activeRecommendationId: nextRec.id,
      showSkipForm: false
    });
  };
  const handleNextRecClick = () => {

    if (!state.activeRecommendationId) {
      return;
    }

    const activeRecommendation = getActiveRecommendation();

    if (activeRecommendation.recommendationData
      && !activeRecommendation.recommendationData.skipReason) {
      mergeState({ showSkipForm: true });
      return;
    }
    const nextIndex = getActiveRecommendationIndex() + 1;
    const nextRec = state.recommendations[nextIndex] || state.recommendations[0];
    mergeState({ activeRecommendationId: nextRec.id });
  };

  const getPrevRec = (): RecommendationDto => {
    const nextIndex = getActiveRecommendationIndex() - 1;
    return state.recommendations[nextIndex] || state.recommendations[state.recommendations.length - 1];
  };

  const handlePrevRecClick = () => {
    const prevRec = getPrevRec();
    mergeState({ activeRecommendationId: prevRec.id });
  };

  const handleLaunch = (selectedRecommendation: RecommendationDto) => {
    mergeState({ selectedRecommendation });
  };

  const isPrevRecDisabled = (): boolean => {
    const prevRec = getPrevRec();
    if (!prevRec || !prevRec.recommendationData) {
      return false;
    }
    return !prevRec.recommendationData.skipReason;
  };

  const contentTypes = getContentTypesFromAssessmentGoal(props.assessmentGoal);
  const goalType = getGoalTypeFromAssessmentGoal(props.assessmentGoal);
  const activeRecommendation = getActiveRecommendation();

  return (
    <RemediationGoalModal
      handleCancelClick={handleCancelClick}
      assessmentGoal={props.assessmentGoal}>

      {
        isGoalComplete(statusCounts) && (
          <RemediationCompleteBox activityType={getActivityTypeFromAssessmentGoal(props.assessmentGoal)} />
        )
      }

      {
        state.selectedRecommendation && (
          <RecommendationOverview
            baseState={props.baseState}
            recommendation={state.selectedRecommendation}
            handleReturnNavigation={
              () => {
                mergeState({ selectedRecommendation: null });
              }
            }
          />
        )
      }

      {
        !state.selectedRecommendation && (
          <div>

            <table className="c-ssa-remediation-goal-modal__goal-table">
              <tbody>
                <tr>
                  <td>
                    <IconWithText iconName="syllabus" iconPrefix="hmds">Instructions</IconWithText>
                  </td>
                  <td>

                    <IsRender isRender={statusCounts.completed < props.assessmentGoal.goal && goalType === RemediationGoalType.TAXON}>
                      Review <strong>{props.assessmentGoal.goal} {getContentTypeDisplay(contentTypes)}</strong> for this topic.
                      We have retrieved relevant review content based on the questions you have missed in this topic.
                    </IsRender>

                    <IsRender isRender={statusCounts.completed < props.assessmentGoal.goal && goalType === RemediationGoalType.CONTENT_TYPE}>
                      Complete <strong>{props.assessmentGoal.goal} {getContentTypeDisplay(contentTypes)}</strong> to complete requirements for this section.
                    </IsRender>

                    <IsRender isRender={statusCounts.completed >= props.assessmentGoal.goal}>
                      <h4>All available {ActivityTypeConfigMap[getActivityTypeFromAssessmentGoal(props.assessmentGoal)].title.toLowerCase()} content</h4>
                    </IsRender>
                  </td>
                </tr>
                <tr>
                  <td>
                    <IconWithText iconName="statistics" iconPrefix="gizmo">Progress</IconWithText>
                  </td>
                  <td>
                    <FlexLayout modifiers={[FlexLayoutModifier.LEFT, FlexLayoutModifier.GUTTERS_1o2]}>
                      <FlexItem>
                        <TotalsStatus completedGoal={props.assessmentGoal.goal} completed={statusCounts.completed} total={statusCounts.total} />
                      </FlexItem>
                      <FlexItem>
                        <IsRender isRender={goalType === RemediationGoalType.TAXON}>
                          {getContentTypeDisplay(contentTypes)} completed in this topic
                        </IsRender>

                        <IsRender isRender={goalType === RemediationGoalType.CONTENT_TYPE}>
                          {getContentTypeDisplay(contentTypes)} requirements met
                        </IsRender>
                      </FlexItem>
                    </FlexLayout>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="o-els-container c-els-divider u-els-color-n0" />

            {statusCounts.completed < statusCounts.total && state.recommendations.length > statusCounts.total && (
              <div className="o-els-container">
                <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X, FlexLayoutModifier.RIGHT]}>
                  <FlexItem>

                    <FlexLayout modifiers={[
                      FlexLayoutModifier.GUTTERS,
                      FlexLayoutModifier.MIDDLE,
                    ]}>
                      <FlexItem>
                        Currently viewing:
                      </FlexItem>
                      <FlexItem>
                        <ELSDropDown
                          value={state.activeView}
                          options={viewOptions}
                          changeHandler={() => {
                            mergeState({
                              activeView: state.activeView === ViewOptionValue.TOP_RECOMMENDATION
                                ? ViewOptionValue.ALL_RECOMMENDATIONS
                                : ViewOptionValue.TOP_RECOMMENDATION
                            });
                          }}
                        />
                      </FlexItem>
                    </FlexLayout>
                  </FlexItem>
                </FlexLayout>
                {
                  state.activeView === ViewOptionValue.TOP_RECOMMENDATION && (
                    <div className="o-els-container">
                      <h4>
                        We suggest you start by reviewing:
                      </h4>
                    </div>
                  )
                }
              </div>
            )}

            {
              state.showSkipForm && state.activeView === ViewOptionValue.TOP_RECOMMENDATION && (
                <div className="c-ssa-remediation-goal-modal__card-wrap">
                  <div className="c-ssa-remediation-goal-modal__skip-form">
                    <div className="o-els-container">Why did you skip the previous recommendation?</div>
                    {skipOptions.map((option) => {
                      return (
                        <div
                          className="o-els-container"
                          key={option.value}
                        >
                          <ELSRadio
                            changeHandler={(e, value) => {
                              setState((prevState) => {
                                const _activeRecommendation = prevState.recommendations.find((rec) => {
                                  return rec.id === prevState.activeRecommendationId;
                                });
                                const updatedRec = {
                                  ..._activeRecommendation,
                                  recommendationData: {
                                    ..._activeRecommendation.recommendationData,
                                    skipReason: value
                                  }
                                };
                                return {
                                  ...prevState,
                                  recommendations: prevState.recommendations.map((rec) => {
                                    if (rec.id === updatedRec.id) {
                                      return updatedRec;
                                    }
                                    return rec;
                                  }),
                                  activeRecommendation: updatedRec
                                };
                              });
                            }}
                            name={option.value as string}
                            value={option.value}
                            checked={activeRecommendation.recommendationData.skipReason === option.value}
                          >
                            {option.name}
                          </ELSRadio>
                        </div>
                      );
                    })}

                  </div>
                  <div className="u-els-margin-top">
                    <FlexLayout modifiers={[FlexLayoutModifier.RIGHT]}>
                      <FlexItem>
                        <button
                          type="button"
                          className="c-els-button c-els-button--small"
                          onClick={() => {
                            handleSkipSubmit();
                          }}
                        >
                          Submit
                        </button>
                      </FlexItem>
                    </FlexLayout>
                  </div>
                </div>
              )
            }

            {
              !state.showSkipForm && state.activeView === ViewOptionValue.TOP_RECOMMENDATION && (
                <div className="c-ssa-remediation-goal-modal__card-wrap">

                  {activeRecommendation && (
                    <div className="o-els-container">
                      <RemediationRecommendation
                        recommendation={activeRecommendation}
                        recommendationAttempts={props.baseState.recommendationAttempts}
                        isExpanded
                        handleLaunch={handleLaunch}
                        launchButtonText="View details"
                      />
                    </div>
                  )}

                  <div className="o-els-container">
                    <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X, FlexLayoutModifier.MIDDLE]}>
                      <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                        {
                          !isPrevRecDisabled() && (
                            <button
                              type="button"
                              className="u-els-anchorize"
                              onClick={handlePrevRecClick}
                            >
                              <IconWithText
                                iconName="arrow-left"
                                iconPrefix="hmds"
                                iconPosition={ELSButtonIconPosition.LEFT}
                              >
                                Previous recommendation
                              </IconWithText>
                            </button>
                          )
                        }
                      </FlexItem>
                      <FlexItem>
                        <button
                          type="button"
                          className="u-els-anchorize"
                          onClick={handleNextRecClick}
                        >
                          <IconWithText
                            iconName="arrow-right"
                            iconPrefix="hmds"
                            iconPosition={ELSButtonIconPosition.RIGHT}
                          >
                            Next recommendation
                          </IconWithText>
                        </button>
                      </FlexItem>
                    </FlexLayout>
                  </div>
                </div>
              )
            }

            {
              state.activeView === ViewOptionValue.ALL_RECOMMENDATIONS && (
                <div className="o-els-container o-els-container--2x">
                  <div className="c-ssa-remediation-goal-modal__list">
                    {state.recommendations && sortRecommendations(state.recommendations, props.baseState.assessment.assessmentGoals)
                      .map((recommendation) => {
                        return (
                          <div
                            key={recommendation.id}
                            className="c-ssa-remediation-goal-modal__list-item"
                          >
                            <RemediationRecommendation
                              recommendationAttempts={props.baseState.recommendationAttempts}
                              recommendation={recommendation}
                              isExpanded
                              handleLaunch={handleLaunch}
                              launchButtonText="View details"
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              )
            }

            {
              state.activeView === ViewOptionValue.TOP_RECOMMENDATION && (
                <div className="u-els-margin-top-3x">
                  <IconWithText
                    iconName="information"
                    iconPrefix="gizmo">
                    Learn more about how we&apos;ve personalized your plan and review content suggestions.
                  </IconWithText>
                </div>
              )
            }

          </div>
        )
      }

    </RemediationGoalModal>
  );
};

const enhancers = [
  connector,
  ELSWithModalService
];

const RemediationActivityModal = compose<null, RemediationActivityModalPropsOnly>(...enhancers)(RemediationActivityModalComponent);

export default RemediationActivityModal;
