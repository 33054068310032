import React from 'react';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import {
  RecommendationAttemptDto,
  RecommendationDto
} from '../../apis/florence-facade/florence-facade.dtos';
import { TimeEstimateDisplay } from './TimeEstimateDisplay.component';
import { ContentTypeDisplay } from './ContentTypeDisplay.component';
import { getRecommendationStatus } from './remediation-home.utilities';
import { RecommendationAttemptStatusDisplayMap } from './remediation-home.constants';
import { StatusPill } from './StatusPill.component';

export type RemediationRecProps = {
  recommendation: RecommendationDto;
  recommendationAttempts: RecommendationAttemptDto[];
  isExpanded: boolean;
  handleLaunch: (recommendation: RecommendationDto) => void;
  launchButtonText: string;
};

const RemediationRecommendation = (props: RemediationRecProps) => {

  if (!props.recommendation) {
    return null;
  }

  const {
    contentType,
  } = props.recommendation;

  const recommendationData: RecommendationDto['recommendationData'] = {
    title: `${props.recommendation.id}: missing title`,
    subtitle: null,
    timeEstimateSeconds: 0,
    topics: null,
    skipReason: null,
    ...props.recommendation.recommendationData || {} as RecommendationDto['recommendationData'],
  };

  const status = getRecommendationStatus({
    recommendation: props.recommendation,
    recommendationAttempts: props.recommendationAttempts
  });

  return (
    <div className="c-ssa-remediation-goal-modal__card">

      <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X]}>
        <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
          <h4>{recommendationData.title}</h4>
        </FlexItem>
        <FlexItem classes={['u-els-nowrap', 'u-els-color-n7']}>
          <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X, FlexLayoutModifier.LEFT]}>
            <FlexItem>
              <ContentTypeDisplay contentType={contentType} />
            </FlexItem>
            <FlexItem>
              <TimeEstimateDisplay timeEstimateSeconds={recommendationData.timeEstimateSeconds} />
            </FlexItem>
          </FlexLayout>
        </FlexItem>
      </FlexLayout>

      {props.isExpanded && (
        <div className="u-els-margin-top">

          {recommendationData.subtitle && (
            <div className="o-els-container">
              {recommendationData.subtitle}
            </div>
          )}

          <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X, FlexLayoutModifier.BOTTOM]}>
            <FlexItem>
              <StatusPill status={status}>
                {RecommendationAttemptStatusDisplayMap[status]}
              </StatusPill>
            </FlexItem>
            <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
              {recommendationData.topics && (
                <div>
                  <div>Topics covered:</div>
                  {
                    recommendationData.topics.map((topic) => {
                      return (
                        <div key={topic}>{topic}</div>
                      );
                    })
                  }
                </div>
              )}
            </FlexItem>
            <FlexItem>
              <button
                type="button"
                className="c-els-button c-els-button--small"
                onClick={() => props.handleLaunch(props.recommendation)}
              >
                {props.launchButtonText}
              </button>
            </FlexItem>
          </FlexLayout>
        </div>
      )}

    </div>
  );
};
export default RemediationRecommendation;
