import React, { useState } from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { compose } from 'recompose';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import { ELSPageHeader } from '@els/els-component-shared-ts-react';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { locationActions } from '../../redux/location/location.actions';
import { RoutePath } from '../../components/app/app.constants';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { locationSelectors } from '../../redux/location/location.selectors';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { RemediationBaseState } from './RemediationBase.page';
import RemediationActivityProgress from './RemediationActivityProgress.component';
import {
  getActivityTypeFromRecommendation,
  getAssessmentGoalFromRecommendation,
  getAssessmentSubmissionByAttempt,
  getAttemptById,
  getContentTypePassNumber,
  getGoalFromRecommendation,
  getIncompleteAttempts,
  getNewAttemptAssessment,
  getNextRecommendation,
  getRecommendationFromAttempt,
  getRecommendationTitle,
  handleNavigateToContent,
} from './remediation-home.utilities';
import {
  RecommendationAttemptDto,
  RecommendationAttemptStatusDto,
  RecommendationDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import RemediationRecommendation from './RemediationRecommendation.component';
import { ELSButtonIconPosition } from '../../models/button.models';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import { RemediationContentTypeConfigMap } from './remediation-home.models';

type RecommendationPostPlayerPropsOnly = {
  baseState: RemediationBaseState;
}

const mapDispatchToProps = {
  trackAction: studyActions.trackAction,
  redirect: locationActions.redirect,
  replaceURL: locationActions.replaceURL,
  navigateToApp: studyActions.navigateToApp,
  postAssessmentAction: studyActions.postAssessmentAction,
  postRemediationRecommendationAttemptAction: studyActions.postRemediationRecommendationAttemptAction,
};
const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  appLinkData: studySelectors.getLinkData(state),
  appLinkCookies: studySelectors.getAppLinkCookies(state),
  userId: studySelectors.getUserId(state),
  courseSectionId: studySelectors.getCourseSectionId(state),
  location: locationSelectors.getLocation(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type RecommendationPostPlayerState = {
  step: number;
}

const defaultState = {
  step: 0
};

export type RecommendationPostPlayerProps = PropsFromRedux & RecommendationPostPlayerPropsOnly & ELSPropsFromModalService;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const RecommendationPostPlayerComponent = (props: RecommendationPostPlayerProps) => {

  const [state, setState] = useState<RecommendationPostPlayerState>(defaultState);
  const mergeState = (newState: Partial<RecommendationPostPlayerState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  const {
    redirect,
    baseState,
    location
  } = props;
  const handleReturnNavigation = () => {
    props.replaceURL(RoutePath.REMEDIATION_BASE);
  };

  const handleNewAttempt = (recommendation: RecommendationDto, recommendationAttempt: RecommendationAttemptDto) => {

    const {
      postAssessmentAction,
      userId,
      postRemediationRecommendationAttemptAction,
    } = props;

    if (recommendationAttempt && recommendationAttempt.status !== RecommendationAttemptStatusDto.COMPLETED) {
      handleNavigateToContent({
        recommendationAttempt,
        recommendation,
        assessment: baseState.assessment,
        navigateToApp: props.navigateToApp,
        appLinkCookies: props.appLinkCookies,
        appLinkData: props.appLinkData,
        quizSession: null,
        redirect
      });
      return;
    }

    const incompleteAttempts = getIncompleteAttempts(baseState.recommendationAttempts, recommendation);

    if (incompleteAttempts && incompleteAttempts.length) {
      handleNavigateToContent({
        recommendationAttempt: incompleteAttempts[0],
        recommendation,
        assessment: baseState.assessment,
        navigateToApp: props.navigateToApp,
        appLinkCookies: props.appLinkCookies,
        appLinkData: props.appLinkData,
        quizSession: null,
        redirect
      });
      return;
    }

    const newAssessment = getNewAttemptAssessment(recommendation);

    if (!newAssessment) {
      // eslint-disable-next-line no-alert
      alert('unable to construct new assessment');
      return;
    }

    postAssessmentAction(userId, newAssessment).then((assessment) => {
      postRemediationRecommendationAttemptAction({
        assessmentId: assessment.id,
        remediationRecommendationId: recommendation.id,
        status: RecommendationAttemptStatusDto.IN_PROGRESS
      }).then((newAttempt) => {
        handleNavigateToContent({
          recommendationAttempt: newAttempt,
          recommendation,
          assessment: baseState.assessment,
          navigateToApp: props.navigateToApp,
          appLinkCookies: props.appLinkCookies,
          appLinkData: props.appLinkData,
          quizSession: null,
          redirect
        });
      });
    });
  };

  const attempt = getAttemptById(baseState.recommendationAttempts, location.query.recommendationAttemptId);
  const recommendation = getRecommendationFromAttempt(baseState.recommendations, attempt);
  const activityType = getActivityTypeFromRecommendation(recommendation);
  const submission = getAssessmentSubmissionByAttempt(attempt, baseState.attemptAssessmentSubmissions);
  const scoreToPass = getContentTypePassNumber(recommendation, baseState);

  if (!recommendation) {
    return null;
  }

  const contentTypeDisplay = RemediationContentTypeConfigMap[recommendation.contentType].displayName.toLowerCase();

  return (
    <div className="c-ssa-remediation-post-player">
      <div className="c-ssa-remediation-post-player__header">
        <ELSPageHeader onCloseClick={handleReturnNavigation} title="Study Assignment" />
      </div>

      <div className="c-ssa-remediation-post-player__body">
        <div className="c-ssa-remediation-post-player__window">

          {
            state.step === 0 && (

              <div>

                <div>
                  <RemediationActivityProgress
                    assessmentGoals={props.baseState.assessment.assessmentGoals}
                    baseState={props.baseState}
                    activityType={activityType}
                  />
                </div>

                <div className="o-els-container o-els-container--2x u-els-text-center">

                  <div className="o-els-container">
                    <div>Current {contentTypeDisplay}:</div>
                    <h4><strong>{getRecommendationTitle(recommendation)}</strong></h4>
                  </div>

                  <div className="o-els-container">
                    <div>Topic:</div>
                    <div><strong>{getGoalFromRecommendation(baseState.assessment.assessmentGoals, recommendation).text}</strong></div>
                  </div>

                </div>

                <h4 className="o-els-container o-els-container--2x u-els-text-center">

                  {
                    submission && submission.score >= scoreToPass && (
                      <div>
                        Great job! You completed your requirement for this {contentTypeDisplay} scoring <strong>{Math.round(submission.score * 100)}%</strong>.
                      </div>
                    )
                  }

                  {
                    submission && submission.score < scoreToPass && (
                      <div>
                        Nice try! You completed this {contentTypeDisplay} scoring <strong>{Math.round(submission.score * 100)}%</strong>.
                        Unfortunately, you are required to score <strong>{Math.round(scoreToPass * 100)}%</strong> to receive credit.
                        You can choose to reattempt using the option below or reattempt this {contentTypeDisplay} at a different time.
                      </div>
                    )
                  }

                  {
                    !submission && (
                      <div>
                        You closed your {contentTypeDisplay} before completing it,
                        you can resume this {contentTypeDisplay} attempt by using the option below or choose to resume it at a different time.
                      </div>
                    )
                  }
                </h4>

              </div>

            )
          }

          {
            state.step === 1 && (
              <div>
                <div className="o-els-container">
                  <FlexLayout>
                    <FlexItem>
                      <button
                        type="button"
                        className="u-els-anchorize"
                        onClick={() => {
                          mergeState({ step: 0 });
                        }}
                      >
                        <IconWithText
                          iconName="arrow-left"
                          iconPrefix="hmds"
                          iconPosition={ELSButtonIconPosition.LEFT}
                        >
                          Go back
                        </IconWithText>
                      </button>
                    </FlexItem>
                  </FlexLayout>
                </div>
                <h3 className="u-els-text-center">Next recommendation</h3>
                <div className="o-els-container">

                  <RemediationRecommendation
                    handleLaunch={() => {
                      handleNewAttempt(getNextRecommendation(baseState, getAssessmentGoalFromRecommendation(baseState.assessment, recommendation)), null);
                    }}
                    recommendation={getNextRecommendation(baseState, getAssessmentGoalFromRecommendation(baseState.assessment, recommendation))}
                    isExpanded
                    recommendationAttempts={baseState.recommendationAttempts}
                    launchButtonText="Launch"
                  />

                </div>

              </div>
            )
          }

          <div className="u-els-margin-top-2x">
            <FlexLayout modifiers={[
              FlexLayoutModifier.GUTTERS,
              FlexLayoutModifier.CENTER,
            ]}>
              <FlexItem>
                <button
                  type="button"
                  className="c-els-button c-els-button--secondary c-els-button--small"
                  onClick={() => redirect(RoutePath.REMEDIATION_BASE)}
                >
                  Back to personalized learning plan
                </button>
              </FlexItem>
              <FlexItem isRender={state.step === 0}>
                <button
                  type="button"
                  className="c-els-button c-els-button--secondary c-els-button--small"
                  onClick={() => {
                    handleNewAttempt(recommendation, attempt);
                  }}
                >
                  {
                    attempt.status === RecommendationAttemptStatusDto.COMPLETED
                      ? 'Start new attempt'
                      : 'Resume attempt'
                  }
                </button>
              </FlexItem>
              <FlexItem isRender={state.step === 0}>
                <button
                  type="button"
                  className="c-els-button c-els-button--primary c-els-button--small"
                  onClick={() => {
                    mergeState({ step: 1 });
                  }}
                >
                  See next recommendation
                </button>

              </FlexItem>
            </FlexLayout>
          </div>

        </div>
      </div>

    </div>
  );
};

const enhancers = [
  connector,
  ELSWithModalService
];

const RecommendationPostPlayer = compose<null, RecommendationPostPlayerPropsOnly>(...enhancers)(RecommendationPostPlayerComponent);

export default RecommendationPostPlayer;
